import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { createComponent, watchRun } from '@/lib/vue';
import { IPageInfoFragment } from '@/typings';
import { Ref, SetupContext } from '@vue/composition-api';
import { DocumentNode } from 'graphql';

interface IProps {
  config: IFetchMoreSearchQuery;
}

interface IEvents {
  onLoading: (loading: boolean) => void;
  onItemsChange: (items: any[]) => void;
}

interface IScopedSlots {
  item?: { item: any };
  allItems?: { items: any[] };
}

export interface IFetchMoreSearchQuery {
  query: DocumentNode;
  name: string;
  variables: () => any;
  mapData: (result: any) => any;
  mapPageInfo: (result: any) => IPageInfoFragment;
}

export const ListFetchMore = createComponent<IProps, IEvents, IScopedSlots>({
  name: 'ListFetchMore',
  props: {
    config: { type: Object, required: true },
  },
  setup(props, ctx) {
    const { $data, $loading, $pageInfo, handleFetchMore } =
      useFetchMoreQuery(props);

    useEvents({ $data, $loading, ctx });

    return () => {
      const itemSlot = ctx.slots.item;
      const allItemsSlot = ctx.slots.allItems;
      const titleSlot = ctx.slots.title?.();
      const defaultSlot = ctx.slots.default?.();

      if (!$data.value?.length) {
        return defaultSlot;
      }

      return (
        <div class="flex flex-col">
          {titleSlot}

          <div class="flex flex-col">
            {itemSlot && $data.value.map(item => itemSlot({ item }))}

            {allItemsSlot?.({ items: $data.value })}

            {defaultSlot}

            {$pageInfo.value?.hasNextPage && (
              <div class="flex justify-center">
                <v-btn
                  text
                  color="accent"
                  loading={$loading.value}
                  onClick={handleFetchMore}
                >
                  ver mais...
                </v-btn>
              </div>
            )}
          </div>
        </div>
      );
    };
  },
});

function useEvents({
  $data,
  $loading,
  ctx,
}: {
  $data: Ref<any[] | null>;
  $loading: Ref<boolean>;
  ctx: SetupContext;
}) {
  function emitLoading(loading: boolean) {
    ctx.emit('loading', loading);
  }

  function emitItemsChange(items: any[] | null) {
    ctx.emit('itemsChange', items || []);
  }

  watchRun($loading, emitLoading);

  watchRun($data, emitItemsChange, { deep: true });
}

function useFetchMoreQuery(props: IProps) {
  const { $data, $pageInfo, $loading, fetchMore } = useQueryConfig<
    any,
    any[] | null,
    any
  >({
    query: props.config.query,
    variables: props.config.variables,
    mapData: props.config.mapData,
    mapPageInfo: props.config.mapPageInfo,
  });

  function handleFetchMore() {
    if (!$pageInfo.value?.hasNextPage) {
      return;
    }

    const { skip, take } = $pageInfo.value;

    const queryName = props.config.name;

    fetchMore({
      variables: {
        ...props.config.variables(),
        skip: skip + take,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        return {
          [queryName]: {
            ...fetchMoreResult[queryName],
            nodes: [
              ...previousResult[queryName].nodes,
              ...fetchMoreResult[queryName].nodes,
            ],
          },
        };
      },
    });
  }

  return { $data, $pageInfo, $loading, handleFetchMore };
}
