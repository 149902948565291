import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IListaEsperaFragment } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import {
  PacienteConvenio,
  PacienteEmail,
  PacienteFones,
  PacienteInfo,
} from '@/components/paciente/PacienteComponents';

interface IProps {
  item: IListaEsperaFragment;
  ordem: number;
}

interface IEvents {
  onRemove: (item: IListaEsperaFragment) => void;
  onAgendar: (item: IListaEsperaFragment) => void;
}

export const ListaEsperaPacientesItem = createComponent<IProps, IEvents>({
  name: 'ListaEsperaPacientesItem',
  props: {
    item: { type: Object, required: true },
    ordem: { type: Number, required: true },
  },
  setup(props, ctx) {
    const { $nome, $celular, $telefoneCasa, $email } = useComputeds(props);

    const { emitAgendar, emitRemove } = useEvents(props, ctx);

    return () => (
      <v-card outlined>
        <div class="flex flex-wrap items-center">
          <v-icon x-large class="drag-icon">
            {MyIcons.drag}
          </v-icon>

          <div class="flex flex-col">
            <div class="mt-2 font-medium text-subtitle text-coolGray-500">
              {props.ordem} - {$nome.value}
            </div>

            {infoRows({ $celular, $telefoneCasa, $email, props })}

            {btnsRow({ emitRemove, emitAgendar })}
          </div>
        </div>
      </v-card>
    );
  },
});

function useComputeds(props: IProps) {
  const $paciente = computed(() => props.item.paciente);

  const $nome = computed(() => $paciente.value?.nome || props.item.nome);

  const $celular = computed(
    () => $paciente.value?.celular || props.item.celular,
  );

  const $telefoneCasa = computed(
    () => $paciente.value?.telefoneCasa || props.item.telefoneCasa,
  );

  const $email = computed(() => $paciente.value?.email || props.item.email);

  return {
    $paciente,
    $nome,
    $celular,
    $telefoneCasa,
    $email,
  };
}

function useEvents(props: IProps, ctx: SetupContext) {
  function emitRemove() {
    ctx.emit('remove', props.item);
  }

  function emitAgendar() {
    ctx.emit('agendar', props.item);
  }

  return { emitRemove, emitAgendar };
}

const infoRows = ({
  $celular,
  $telefoneCasa,
  $email,
  props,
}: {
  $celular: Ref<string | null>;
  $telefoneCasa: Ref<string | null>;
  $email: Ref<string | null>;
  props: IProps;
}) => {
  const { convenio, observacao } = props.item;

  return (
    <div class="flex flex-col text-gray-700 text-small">
      <PacienteFones
        celular={$celular.value}
        telefoneCasa={$telefoneCasa.value}
        classes="mb-1"
        small
      />

      <PacienteEmail email={$email.value} classes="mb-1" small />

      <PacienteConvenio convenio={convenio?.nome} classes="mb-1" small />

      <PacienteInfo value={observacao} icon={MyIcons.note} small />
    </div>
  );
};

const btnsRow = ({
  emitRemove,
  emitAgendar,
}: {
  emitRemove: () => void;
  emitAgendar: () => void;
}) => (
  <div class="flex justify-center mb-2">
    <v-btn text color="primary" small class="mr-2" onClick={emitRemove}>
      Remover
    </v-btn>

    <v-btn outlined rounded small color="primary" onClick={emitAgendar}>
      Agendar
    </v-btn>
  </div>
);
