import { PacienteConvenio } from '@/components/paciente/PacienteComponents';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { IAgendamentoFragment } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  agendamento: IAgendamentoFragment;
}

export const AgendamentoRecebimentoHeader = createComponent<IProps>({
  name: 'AgendamentoRecebimentoHeader',
  props: {
    agendamento: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $dataAgendamento = computed(() =>
      DateHelpers.dataAgendamento(props.agendamento),
    );

    return () => {
      const { profissional, paciente, convenio } = props.agendamento;

      return (
        <div class="flex flex-col">
          <h1 class="font-normal text-title text-secondary">
            {$dataAgendamento.value}
          </h1>

          <div class="flex items-center mt-4 mb-2">
            <v-icon size={64} class="mr-4">
              {MyIcons.money}
            </v-icon>

            <div class="flex flex-col">
              {paciente && <div class="mb-2 text-title">{paciente.nome}</div>}

              <div class="flex items-center">
                <div class="mr-12 text-gray-800 text-subtitle">
                  {profissional.nome}
                </div>

                <PacienteConvenio convenio={convenio?.nome} />
              </div>
            </div>
          </div>
        </div>
      );
    };
  },
});
