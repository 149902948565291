import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import {
  IAgendamentoFormModel,
  IAgendamentoFormSchema,
  IForm,
} from '@/typings';

interface IAgendamentoFieldsProps {
  form: IForm<IAgendamentoFormModel>;
  schema: IAgendamentoFormSchema;
}

export const AgendamentoFormTipoFields =
  createComponent<IAgendamentoFieldsProps>({
    name: 'AgendamentoFormTipoFields',
    props: {
      form: { type: Object, required: true },
      schema: { type: Object, required: true },
    },
    setup(props, ctx) {
      return () => (
        <PageSection title="Tipo">
          <FormFields
            slot="fields"
            form={props.form}
            schema={props.schema.tipo}
            v-model={props.form.model.tipo}
          />
        </PageSection>
      );
    },
  });

export const AgendamentoFormPacienteFields = createComponent<
  IAgendamentoFieldsProps & { isBloqueado: boolean }
>({
  name: 'AgendamentoFormPacienteFields',
  props: {
    isBloqueado: { type: Boolean, required: true },
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection divider hide={props.isBloqueado} title="Paciente">
        <FormFields
          slot="fields"
          form={props.form}
          schema={props.schema.paciente}
          v-model={props.form.model.paciente}
        />
      </PageSection>
    );
  },
});

export const AgendamentoFormHorarioFields = createComponent<
  IAgendamentoFieldsProps & {
    isBloqueado: boolean;
    loadingProximoHorario: boolean;
    handleProximoHorario: () => Promise<void>;
  }
>({
  name: 'AgendamentoFormHorarioFields',
  props: {
    isBloqueado: { type: Boolean, required: true },
    loadingProximoHorario: { type: Boolean, required: true },
    handleProximoHorario: { type: Function, required: true },
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => (
      <PageSection divider title="Horário">
        <div class="flex items-center" slot="fields">
          <FormFields
            form={props.form}
            schema={props.schema.time}
            v-model={props.form.model.time}
          />

          {!props.isBloqueado && (
            <v-btn
              color="primary"
              outlined
              class="mt-2"
              onClick={props.handleProximoHorario}
              disabled={props.loadingProximoHorario}
            >
              <v-icon left>{MyIcons.time}</v-icon>
              Próximo horário
            </v-btn>
          )}
        </div>
      </PageSection>
    );
  },
});

export const AgendamentoFormOutrasInformacoesFields =
  createComponent<IAgendamentoFieldsProps>({
    name: 'AgendamentoFormOutrasInformacoesFields',
    props: {
      form: { type: Object, required: true },
      schema: { type: Object, required: true },
    },
    setup(props, ctx) {
      return () => (
        <PageSection divider title="Outras informações">
          <FormFields
            slot="fields"
            form={props.form}
            schema={props.schema.outrasInformacoes}
            v-model={props.form.model.outrasInformacoes}
          />
        </PageSection>
      );
    },
  });
