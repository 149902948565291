import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAgendaPage } from '@/lib/composables/page/useAgendaPage';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IAgendamentoRecebimentoFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { AgendamentoRecebimentoForm } from '../components/forms/AgendamentoRecebimentoForm';

export default createComponent({
  name: 'AgendamentoRecebimentoPage',
  setup(props, ctx) {
    const $numTransacoes = computed(
      () => parseInt(useRoute().query.rec as string, 10) || 0,
    );

    const $title = computed(() =>
      $numTransacoes.value > 0 ? 'Editar recebimento' : 'Lançar recebimento',
    );

    const { profissionalId } = useAgendaPage();

    const { page, handleSubmit } =
      useFormPage<IAgendamentoRecebimentoFormModel>({
        ctx,
        async submitCallback(model) {
          if (!page.id) return;

          if ($numTransacoes.value === 0) {
            AgendamentoService.createRecebimento({
              agendamentoId: page.id,
              profissionalId,
              model,
            });
          } else if ($numTransacoes.value === 1) {
            AgendamentoService.updateRecebimento({
              agendamentoId: page.id,
              profissionalId,
              model,
            });
          }

          goToAgenda();
        },
      });

    function goToAgenda() {
      useRouter().push(Routes.app.agenda(profissionalId).index);
    }

    function handleRecebimentoStatusChanged({
      transacaoId,
      pago,
    }: {
      transacaoId: string;
      pago: boolean;
    }) {
      if (!page.id) {
        return;
      }

      return AgendamentoService.updateRecebimentoStatus({
        agendamentoId: page.id,
        profissionalId,
        transacaoId,
        pago,
      });
    }

    return () => (
      <MyPage title={$title.value} form>
        <AgendamentoRecebimentoForm
          page={page}
          onSubmit={handleSubmit}
          onCancel={goToAgenda}
          onRecebimentoStatusChanged={handleRecebimentoStatusChanged}
        />
      </MyPage>
    );
  },
});
