import { MyForm } from '@/components/form/MyForm';
import {
  PacienteDataNascimento,
  PacienteFones,
} from '@/components/paciente/PacienteComponents';
import { useState, useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { useRoute, useRouter } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { getPacienteConvenio } from '@/lib/helpers/models/paciente';
import {
  AgendamentoService,
  ListaEsperaService,
  PacienteService,
} from '@/lib/services';
import { createComponent, watchRun } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IAgendamentoFormModel,
  IAgendamentoFormSchema,
  IForm,
  IFormValidationErrors,
  IListaEsperaFragment_paciente,
  IPacienteFragment,
  TipoRepeticao,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import isString from 'lodash/isString';
import {
  AgendamentoFormHorarioFields,
  AgendamentoFormOutrasInformacoesFields,
  AgendamentoFormPacienteFields,
  AgendamentoFormTipoFields,
} from './agendamentoForm/agendamentoFormFields';
import { AgendamentoProcedimentosFields } from './agendamentoForm/AgendamentoProcedimentosFields';
import { AgendamentoRecorrenciaFields } from './agendamentoForm/AgendamentoRecorrenciaFields';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IAgendamentoFormModel> {}

export const AgendamentoForm = createComponent<IProps, IEvents>({
  name: 'AgendamentoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $profissionalId = useState(s => s.agenda.profissionalId);

    const [$hasRecebimentos, setHasRecebimentos] = useValue(false);

    const $indexRoute = computed(
      () =>
        $profissionalId.value && Routes.app.agenda($profissionalId.value).index,
    );

    const {
      $form,
      $schema,
      emitSubmit,
      emitDelete,
      isEdit,
      setPaciente,
      handleRecorrenciaValidation,
      $isBloqueado,
    } = useForm({
      ctx,
      props,
      $indexRoute,
      setHasRecebimentos,
    });

    const { handleProximoHorario, $loadingProximoHorario } = useData({
      $profissionalId,
      $form,
    });

    useParams({ ctx, $form, handleProximoHorario, setPaciente });

    return () => (
      <MyForm
        form={$form.value}
        cancelTo={$indexRoute.value}
        onSubmit={emitSubmit}
        onDelete={emitDelete}
      >
        <AgendamentoFormTipoFields form={$form.value} schema={$schema.value} />

        <AgendamentoProcedimentosFields
          form={$form.value}
          hasRecebimentos={$hasRecebimentos.value}
          hide={$isBloqueado.value}
          v-model={$form.value.model.procedimentos}
        />

        <AgendamentoFormPacienteFields
          form={$form.value}
          schema={$schema.value}
          isBloqueado={$isBloqueado.value}
        />

        <AgendamentoFormHorarioFields
          form={$form.value}
          schema={$schema.value}
          isBloqueado={$isBloqueado.value}
          loadingProximoHorario={$loadingProximoHorario.value}
          handleProximoHorario={handleProximoHorario}
        />

        {!isEdit && (
          <AgendamentoRecorrenciaFields
            form={$form.value}
            v-model={$form.value.model.recorrencia}
            onValidation={handleRecorrenciaValidation}
          />
        )}

        <AgendamentoFormOutrasInformacoesFields
          form={$form.value}
          schema={$schema.value}
        />
      </MyForm>
    );
  },
});

function useData({
  $profissionalId,
  $form,
}: {
  $profissionalId: Ref<string | null>;
  $form: Ref<IForm<IAgendamentoFormModel>>;
}) {
  const [$loadingProximoHorario, setLoadingProximoHorario] = useValue(false);

  const $duracaoMinutos = computed(() =>
    $form.value.model.procedimentos.reduce(
      (total, procedimento) => total + (procedimento.duracaoMinutos || 0),
      0,
    ),
  );
  watchRun($duracaoMinutos, handleDuracaoMinutosChange);

  function handleDuracaoMinutosChange() {
    if ($duracaoMinutos.value > 0 && !$form.value.loading) {
      if ($form.value.model.time.horaInicial) {
        $form.value.model.time.horaFinal = DateHelpers.formatHour(
          DateHelpers.dateAdd($form.value.model.time.horaInicial, {
            minutes: $duracaoMinutos.value,
          }),
        );
      } else {
        handleProximoHorario();
      }
    }
  }

  async function handleProximoHorario() {
    if ($duracaoMinutos.value < 1 || !$profissionalId.value) {
      return;
    }

    setLoadingProximoHorario(true);

    if (!$form.value.model.time.data || !$form.value.model.time.horaInicial) {
      $form.value.model.time.data = DateHelpers.toISODate(new Date());
      $form.value.model.time.horaInicial = DateHelpers.formatHour(
        DateHelpers.dateMinuteSlots(new Date()),
      );
    }

    const result = await AgendamentoService.getProximoHorario({
      data: $form.value.model.time.data!,
      hora: $form.value.model.time.horaInicial!,
      duracaoMinutos: $duracaoMinutos.value,
      profissionalId: $profissionalId.value,
    });
    if (result) {
      $form.value.model.time.data = result.data;
      $form.value.model.time.horaInicial = DateHelpers.formatHour(
        result.horaInicial,
      );
      $form.value.model.time.horaFinal = DateHelpers.formatHour(
        result.horaFinal,
      );
    }

    setLoadingProximoHorario(false);
  }

  return {
    handleProximoHorario,
    $profissionalId,
    $loadingProximoHorario,
    setLoadingProximoHorario,
  };
}

function useParams({
  ctx,
  $form,
  handleProximoHorario,
  setPaciente,
}: {
  ctx: SetupContext;
  $form: Ref<IForm<IAgendamentoFormModel>>;
  handleProximoHorario: () => Promise<void>;
  setPaciente: (
    model: string | IPacienteFragment | IListaEsperaFragment_paciente | null,
  ) => void;
}) {
  function timeParams() {
    const data = useRoute().query.dt as string | null;
    const horaInicial = useRoute().query.hi as string | null;

    if (DateHelpers.isDateValid(data) && DateHelpers.isHourValid(horaInicial)) {
      $form.value.model.time.data = data;
      $form.value.model.time.horaInicial = horaInicial;
    } else {
      handleProximoHorario();
    }
  }

  async function loadPaciente() {
    const pacienteId = useRoute().query.pid as string | null;

    if (!pacienteId) return;

    const paciente = await PacienteService.getById(pacienteId);

    if (!paciente) return;

    $form.value.model.paciente.lookup = paciente;

    setPaciente(paciente);
  }

  async function loadListaEspera() {
    const listaEsperaId = useRoute().query.leid as string | null;

    if (!listaEsperaId) return;

    const listaEspera = await ListaEsperaService.getById(listaEsperaId);

    if (!listaEspera) return;

    if (listaEspera.paciente) {
      $form.value.model.paciente.lookup = listaEspera.paciente;

      setPaciente(listaEspera.paciente);
    } else {
      $form.value.model.paciente.lookup = listaEspera.nome;
      $form.value.model.paciente.celular = listaEspera.celular;
      $form.value.model.paciente.telefoneCasa = listaEspera.telefoneCasa;
      $form.value.model.paciente.email = listaEspera.email;
    }

    if (!$form.value.model.paciente.convenioId) {
      $form.value.model.paciente.convenioId = listaEspera.convenio?.id;
    }
  }

  timeParams();
  loadPaciente();
  loadListaEspera();

  return { timeParams, loadPaciente, loadListaEspera };
}

function useForm({
  props,
  ctx,
  $indexRoute,
  setHasRecebimentos,
}: {
  props: IProps;
  ctx: SetupContext;
  $indexRoute: Ref<string | null>;
  setHasRecebimentos: (v: boolean) => void;
}) {
  const [$recorrenciaErrors, setRecorrenciaErrors] = useValue<
    IFormValidationErrors[]
  >([]);

  function handleRecorrenciaValidation(errors: IFormValidationErrors[]) {
    setRecorrenciaErrors(errors || []);
  }

  function setPaciente(
    model: string | IPacienteFragment | IListaEsperaFragment_paciente | null,
  ) {
    if (!model) {
      $form.value.model.paciente.pacienteId = null;
      $form.value.model.paciente.nome = null;
      $form.value.model.paciente.celular = null;
      $form.value.model.paciente.telefoneCasa = null;
      $form.value.model.paciente.email = null;

      return;
    }

    if (isString(model)) {
      $form.value.model.paciente.pacienteId = null;
      $form.value.model.paciente.nome = model;

      return;
    }

    $form.value.model.paciente.pacienteId = model.id;
    $form.value.model.paciente.nome = model.nome;
    $form.value.model.paciente.celular = model.celular;
    $form.value.model.paciente.telefoneCasa = model.telefoneCasa;
    $form.value.model.paciente.email = model.email;

    if ('convenios' in model) {
      $form.value.model.paciente.convenioId = getPacienteConvenio(model);
    }
  }

  const { $form, $schema, emitSubmit, emitDelete, isEdit } = useFormConfig<
    IAgendamentoFormModel,
    IAgendamentoFormSchema
  >({
    page: props.page,
    initialValue: {
      tipo: {
        tipo: 'Agendamento',
      },
      procedimentos: [],
      paciente: {
        lookup: null,
        pacienteId: null,
        nome: null,
        celular: null,
        telefoneCasa: null,
        email: null,
        convenioId: null,
      },
      time: {
        data: null,
        horaInicial: null,
        horaFinal: null,
        diaTodo: false,
      },
      outrasInformacoes: {
        observacao: null,
      },
      recorrencia: {
        repetir: {
          repetir: false,
          tipo: TipoRepeticao.SEMANALMENTE,
        },
        terminaEm: {
          tipo: null,
          ocorrencias: null,
          dataFinal: null,
        },
        diasSemana: {
          domingo: false,
          segunda: false,
          terca: false,
          quarta: false,
          quinta: false,
          sexta: false,
          sabado: false,
        },
      },
    },
    mapSchema: model => {
      const isBloqueado = model.tipo.tipo === 'Horário bloqueado';

      const $isFoneRequired = computed(
        () =>
          !isBloqueado &&
          !model.paciente.celular &&
          !model.paciente.telefoneCasa,
      );

      const $pacienteHint = computed(() => {
        const { pacienteId, nome } = model.paciente;

        if (!pacienteId && !nome) {
          return 'Selecione um paciente ou digite o nome para cadastrá-lo';
        } else if (!pacienteId && nome) {
          return 'Novo paciente será cadastrado';
        }

        return undefined;
      });

      const $diaTodo = computed(() => {
        if (!isBloqueado) return false;

        return model.time.diaTodo;
      });

      return {
        tipo: {
          tipo: {
            label: null,
            type: 'radio',
            items: ConstantsHelper.agendamentoTipos,
            layout: { row: true },
            hideDetails: true,
          },
        },
        paciente: {
          lookup: {
            label: 'Paciente',
            type: 'combobox',
            itemLabel: 'nome',
            itemSlot: pacienteAutocompleteItem,
            lookup: LookupsConfigs.paciente({
              onModelChange: value => setPaciente(value),
            }),
            hint: $pacienteHint.value,
            persistentHint: true,
            validations: {
              required: !isBloqueado,
            },
          },
          celular: {
            label: 'Celular',
            type: 'text',
            mask: 'celular',
            validations: {
              required: $isFoneRequired.value,
            },
            layout: {
              sm: 6,
              maxWidth: 260,
            },
          },
          telefoneCasa: {
            label: 'Telefone residencial',
            type: 'text',
            mask: 'telefone',
            validations: {
              required: $isFoneRequired.value,
            },
            layout: {
              sm: 6,
              maxWidth: 258,
            },
          },
          email: {
            label: 'Email',
            type: 'email',
            validations: { email: true },
          },
          convenioId: {
            label: 'Convênio',
            type: 'autocomplete',
            itemLabel: 'nome',
            lookup: LookupsConfigs.convenio(),
            validations: {
              required: !isBloqueado,
            },
          },
        },
        time: {
          data: {
            label: 'Data',
            type: 'date',
            validations: { required: true },
            layout: {
              input: { maxWidth: 170 },
            },
          },
          horaInicial: {
            label: 'Hora inícial',
            type: 'time',
            disabled: $diaTodo.value,
            validations: {
              required: true,
              maxHour: model.time.horaFinal,
            },
            layout: { width: 200 },
          },
          horaFinal: {
            label: 'Hora final',
            type: 'time',
            disabled: $diaTodo.value,
            validations: {
              required: true,
              minHour: model.time.horaInicial,
            },
            layout: { width: 200 },
          },
          diaTodo: {
            label: 'Dia todo',
            type: 'switch',
            hidden: !isBloqueado,
            layout: { width: 140 },
          },
        },
        outrasInformacoes: {
          observacao: {
            label: 'Observação',
            type: 'textarea',
            layout: { minWidth: 300 },
          },
        },
      };
    },
    ctx,
    mapCustomErrors: model => {
      const errors: IFormValidationErrors[] = [];

      const isBloqueado = model.tipo.tipo === 'Horário bloqueado';

      if (!isBloqueado) {
        const procedimentos = model.procedimentos;

        const procLabel = 'Procedimentos';

        const validateQuantidade = (qtd: number | null) =>
          !qtd || qtd <= 0 || qtd > 99;

        if (
          procedimentos.some(
            v => !v.procedimentoId && validateQuantidade(v.quantidade),
          )
        ) {
          errors.push({
            label: procLabel,
            error: 'procedimento e quantidade são obrigatórios',
          });
        } else if (procedimentos.some(v => !v.procedimentoId)) {
          errors.push({
            label: procLabel,
            error: 'procedimento é obrigatório',
          });
        } else if (procedimentos.some(v => validateQuantidade(v.quantidade))) {
          errors.push({
            label: procLabel,
            error: 'quantidade é obrigatória',
          });
        }
      }

      return [...errors, ...$recorrenciaErrors.value];
    },
    async loadEditCallback({ id, setFormModel }) {
      const editValue = await AgendamentoService.getById(id);

      if (!editValue) {
        return $indexRoute.value && useRouter().replace($indexRoute.value);
      }

      setHasRecebimentos(!!editValue.transacoes?.length);

      const paciente = editValue.paciente;

      const procedimentos = editValue.procedimentos || [];

      setFormModel({
        tipo: {
          tipo: editValue.bloqueado ? 'Horário bloqueado' : 'Agendamento',
        },
        procedimentos: procedimentos.map(v => ({
          id: v.id,
          procedimentoId: v.procedimento.id,
          quantidade: v.quantidade,
          duracaoMinutos: v.procedimento.duracaoMinutos,
        })),
        paciente: {
          lookup: paciente,
          pacienteId: paciente?.id,
          nome: paciente?.nome,
          celular: paciente?.celular,
          telefoneCasa: paciente?.telefoneCasa,
          email: paciente?.email,
          convenioId: editValue.convenio?.id,
        },
        time: {
          data: editValue.data,
          horaInicial: DateHelpers.formatHour(editValue.horaInicial),
          horaFinal: DateHelpers.formatHour(editValue.horaFinal),
          diaTodo: editValue.diaTodo,
        },
        outrasInformacoes: {
          observacao: editValue.observacao,
        },
        recorrencia: null,
        recorrenciaId: editValue.recorrencia?.id,
      });
    },
  });

  const $isBloqueado = computed(
    () => $form.value.model.tipo.tipo === 'Horário bloqueado',
  );

  return {
    $form,
    $schema,
    emitSubmit,
    emitDelete,
    isEdit,
    setPaciente,
    handleRecorrenciaValidation,
    $isBloqueado,
  };
}

const pacienteAutocompleteItem =
  () =>
  ({
    item: { nome, dataNascimento, celular, telefoneCasa },
  }: {
    item: IPacienteFragment;
  }) => {
    return (
      <div class="flex flex-col">
        <div class="text-body">{nome}</div>

        <div class="flex">
          <PacienteDataNascimento
            dataNascimento={dataNascimento}
            small
            classes="text-gray-600 text-small mr-6"
          />

          <PacienteFones
            celular={celular}
            telefoneCasa={telefoneCasa}
            small
            classes="text-gray-600 text-small"
          />
        </div>
      </div>
    );
  };
