import { InputWeek } from '@/components/form/inputs/InputWeek';
import { useBreakpoints } from '@/lib/composables/utils/useBreakpoints';
import { useRouteParams } from '@/lib/composables/utils/useRouter';
import { ConstantsHelper } from '@/lib/constants/helper';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { ICalendarDateRange, ICalendarView } from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { DateNavigationBtns } from './DateNavigationBtns';

interface IProps {
  view: ICalendarView;
  range: ICalendarDateRange | null;
}

interface IEvents {
  onRangeChange: (range: ICalendarDateRange | null) => void;
  onToggleView: (view: ICalendarView) => void;
  onToday: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export const CalendarHeaderRow = createComponent<IProps, IEvents>({
  name: 'CalendarHeaderRow',
  props: {
    view: { type: String as any, required: true },
    range: { type: Object },
  },
  setup(props, ctx) {
    const {
      handleRangeChange,
      handleToggleView,
      handleToday,
      handlePrevious,
      handleNext,
    } = useEvents(ctx);

    const {
      $showToggleView: $showToggleView,
      $previousTooltip: $previousTooltip,
      $nextTooltip: $nextTooltip,
    } = useComputeds(props);

    return () => (
      <div id="CalendarHeaderRow" class="flex flex-col">
        <ActionsRow />

        <div class="flex items-center pr-2 my-4">
          <InputWeek
            dayView={props.view === 'timeGridDay'}
            value={props.range}
            class="mr-4"
            onInput={handleRangeChange}
          />

          {viewSelect({ $showToggleView, props, handleToggleView })}

          <v-spacer />

          <DateNavigationBtns
            previousTooltip={$previousTooltip.value}
            nextTooltip={$nextTooltip.value}
            onToday={handleToday}
            onPrevious={handlePrevious}
            onNext={handleNext}
          />
        </div>
      </div>
    );
  },
});

function useEvents(ctx: SetupContext) {
  function handleRangeChange(range: ICalendarDateRange | null) {
    ctx.emit('rangeChange', range);
  }

  function handleToggleView(view: ICalendarView) {
    ctx.emit('toggleView', view);
  }

  function handleToday() {
    ctx.emit('today');
  }

  function handlePrevious() {
    ctx.emit('previous');
  }

  function handleNext() {
    ctx.emit('next');
  }

  return {
    handleRangeChange,
    handleToggleView,
    handleToday,
    handlePrevious,
    handleNext,
  };
}

function useComputeds(props: IProps) {
  const { $width } = useBreakpoints();

  const $showToggleView = computed(() => $width.value >= 700);

  const $isDia = computed(() => props.view === 'timeGridDay');

  const $previousTooltip = computed(() =>
    $isDia.value ? 'Dia anterior' : 'Semana anterior',
  );

  const $nextTooltip = computed(() =>
    $isDia.value ? 'Próximo dia' : 'Próxima semana',
  );

  return {
    $showToggleView,
    $previousTooltip,
    $nextTooltip,
    $isDia,
  };
}

const ActionsRow = createComponent({
  name: 'CalendarHeaderRowActionsRow',
  setup(props, ctx) {
    const { profissionalId } = useRouteParams();

    const routeListaEspera = Routes.app.agenda(profissionalId).listaEspera;
    const routeNewAgendamento = Routes.app
      .agenda(profissionalId)
      .agendamentos.new();

    return () => (
      <div class="flex">
        <v-spacer />

        <v-btn
          color="primary"
          outlined
          exact
          to={routeListaEspera}
          class="mx-2"
        >
          <v-icon left>{MyIcons.listaEspera}</v-icon>
          Lista de espera
        </v-btn>

        <v-btn color="primary" exact to={routeNewAgendamento}>
          <v-icon left>{MyIcons.agendamentoNew}</v-icon>
          Novo Agendamento
        </v-btn>
      </div>
    );
  },
});

const viewSelect = ({
  $showToggleView,
  props,
  handleToggleView,
}: {
  $showToggleView: Ref<boolean>;
  props: IProps;
  handleToggleView: (view: ICalendarView) => void;
}) =>
  $showToggleView.value && (
    <div style={{ maxWidth: '120px' }}>
      <v-select
        outlined
        value={props.view}
        itemText="label"
        items={ConstantsHelper.calendarViews}
        hideDetails
        label="Tipo"
        onChange={handleToggleView}
      />
    </div>
  );
