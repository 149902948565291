import { MyCalendar } from '@/components/calendar/MyCalendar';
import { InputSearch } from '@/components/form/inputs/InputSearch';
import { CircularLoading } from '@/components/loading/CircularLoading';
import { MyPage } from '@/components/page/MyPage';
import { QueryGraphql } from '@/graphql/query';
import { useState, useValue } from '@/lib/composables';
import { useAgendaPage } from '@/lib/composables/page/useAgendaPage';
import { useAuthInfo } from '@/lib/composables/useAuthInfo';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { AppState } from '@/store/modules/root.store';
import {
  IAgendamentoFragment,
  IAgendamentosQuery,
  IAgendamentosQueryVariables,
  ICalendarEventExtendedProps,
  IDateRangeModel,
  IRouteAgendamentoNewOptions,
} from '@/typings';
import { EventApi } from '@fullcalendar/core';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { AgendaPacienteSearch } from '../components/search/AgendaPacienteSearch';

export default createComponent({
  name: 'AgendaPage',
  setup(props, ctx) {
    const { profissionalId } = useAgendaPage();

    const [$search] = useValue('');

    const [$dataInicial, setDataInicial] = useValue(
      DateHelpers.weekStart(DateHelpers.today())!.toISODate(),
    );

    const [$dataFinal, setDataFinal] = useValue(
      DateHelpers.weekEnd(DateHelpers.today())!.toISODate(),
    );

    AppState.loadingOff();

    const {
      handleDatesChange,
      handleDateClick,
      handleEventClick,
      handleEventChange,
    } = useActions({
      profissionalId,
      setDataInicial,
      setDataFinal,
      ctx,
    });

    const { $config, $title, $agendamentos, $loading } = useComputed({
      ctx,
      profissionalId,
      $dataFinal,
      $dataInicial,
    });

    return () => (
      <MyPage title={$title.value}>
        <InputSearch
          placeholder="Pesquisar paciente por  nome, CPF, telefone, código..."
          v-model={$search.value}
        />

        {$search.value && <AgendaPacienteSearch search={$search.value} />}

        {!$search.value && (
          <CircularLoading loading={!$config.value}>
            {$config.value && (
              <MyCalendar
                v-model={$agendamentos.value}
                config={$config.value}
                loading={$loading.value}
                onDatesChange={handleDatesChange}
                onDateClick={handleDateClick}
                onEventClick={handleEventClick}
                onEventChange={handleEventChange}
              />
            )}
          </CircularLoading>
        )}
      </MyPage>
    );
  },
});

function useComputed({
  ctx,
  profissionalId,
  $dataInicial,
  $dataFinal,
}: {
  ctx: SetupContext;
  profissionalId: string;
  $dataInicial: Ref<string>;
  $dataFinal: Ref<string>;
}) {
  const $config = useState(s => s.agenda.config);

  const { $userIsProfissionalSaude, $user } = useAuthInfo();

  const $title = computed(() => {
    const agenda = 'Agenda';

    const user = $user.value!;

    if ($userIsProfissionalSaude.value) {
      return `${agenda}: ${user.nome}`;
    }

    const profissionais = user.profissionais;
    if (!profissionais || profissionais.length === 0) {
      return agenda;
    }

    const nome = profissionais.find(f => f.id === profissionalId)?.nome;

    return nome ? `${agenda}: ${nome}` : agenda;
  });

  const { $data: $agendamentos, $loading } = useQueryConfig<
    IAgendamentosQuery,
    IAgendamentoFragment[],
    IAgendamentosQueryVariables
  >({
    query: QueryGraphql.AgendamentosQuery,
    variables: () => ({
      where: {
        data_gte: $dataInicial.value,
        data_lte: $dataFinal.value,
      },
      profissionalId,
    }),
    mapData: result => result?.agendamentos.nodes || [],
  });

  return {
    $config,
    $title,
    $agendamentos,
    $loading,
  };
}

function useActions({
  profissionalId,
  setDataInicial,
  setDataFinal,
  ctx,
}: {
  profissionalId: string;
  setDataInicial: (v: string) => void;
  setDataFinal: (v: string) => void;
  ctx: SetupContext;
}) {
  function routeNewAgendamento(options?: IRouteAgendamentoNewOptions) {
    return Routes.app.agenda(profissionalId).agendamentos.new(options);
  }

  function handleDatesChange(range: IDateRangeModel | null) {
    if (!range || !range.startDate || !range.endDate) {
      return;
    }

    setDataInicial(range.startDate);
    setDataFinal(range.endDate);
  }

  function handleDateClick(date: Date) {
    useRouter().push(
      routeNewAgendamento({
        data: DateHelpers.toISODate(date)!,
        horaInicial: DateHelpers.formatHour(date)!,
      }),
    );
  }

  function handleEventClick({ extendedProps }: EventApi) {
    const { agendamento } = extendedProps as ICalendarEventExtendedProps;

    AgendamentoService.openViewDialog(agendamento);
  }

  function handleEventChange({
    id,
    start,
    end,
    extendedProps: { diaTodo },
  }: EventApi) {
    return AgendamentoService.updateTime({
      id,
      profissionalId,
      time: {
        data: DateHelpers.toISODate(start),
        horaInicial: DateHelpers.toISOTime(start),
        horaFinal: DateHelpers.toISOTime(end),
        diaTodo,
      },
    });
  }

  return {
    routeNewAgendamento,
    handleDatesChange,
    handleDateClick,
    handleEventClick,
    handleEventChange,
  };
}
