import { TdCheckbox } from '@/components/datatable/columns/TdCheckbox';
import { DataTable } from '@/components/datatable/DataTable';
import { PageSection } from '@/components/page/PageSection';
import { FormHeader } from '@/components/typography/FormHeader';
import { QueryGraphql } from '@/graphql/query';
import { useQueryConfig } from '@/lib/composables/useQueryConfig';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { getTransacaoParcela } from '@/lib/helpers/models/transacao';
import { createComponent } from '@/lib/vue';
import {
  IAgendamentoFragment_transacoes,
  IAgendamentoQuery,
  IAgendamentoQueryVariables,
  IFormInputCheckbox,
  IDataTableHeader,
} from '@/typings';
import { SetupContext } from '@vue/composition-api';

interface IProps {
  agendamentoId: string;
}

interface IEvents {
  onRecebimentoStatusChanged: (values: {
    transacaoId: string;
    pago: boolean;
  }) => void;
}

export const AgendamentoRecebimentoParcelasTable = createComponent<
  IProps,
  IEvents
>({
  name: 'AgendamentoRecebimentoParcelasTable',
  props: {
    agendamentoId: { type: String, required: true },
  },
  setup(props, ctx) {
    const headers = useHeaders(ctx);

    const { $data } = useAgendamentoQuery(props);

    return () => (
      <PageSection divider>
        <FormHeader title="Parcelas" />

        <DataTable
          noMarginBottom
          headers={headers}
          items={$data.value}
          sortBy="vencimento"
        />
      </PageSection>
    );
  },
});

const schema: { pago: IFormInputCheckbox } = {
  pago: {
    label: null,
    type: 'checkbox',
  },
};

function useHeaders(
  ctx: SetupContext,
): IDataTableHeader<IAgendamentoFragment_transacoes>[] {
  return [
    {
      text: 'Parcela',
      value: 'parcela',
      mapValue: v => getTransacaoParcela(v),
    },
    {
      text: 'Vencimento',
      value: 'vencimento',
      mapValue: v => DateHelpers.formatDate(v.vencimento),
      align: 'center',
      valueAlign: 'center',
      width: 100,
    },
    {
      text: 'Valor',
      value: 'valor',
      mapValue: v => v.valor,
      align: 'center',
      valueAlign: 'center',
      money: true,
      width: 140,
    },
    {
      text: 'Recebido',
      value: 'pago',
      slot: ({ header, item }) => (
        <TdCheckbox
          header={header}
          input={schema.pago}
          value={item.pago}
          onInput={pago => emitRecebimentoStatusChanged({ ctx, item, pago })}
        />
      ),
      align: 'center',
      valueAlign: 'center',
      width: 60,
      sortable: false,
    },
    {
      text: 'Data do Recebimento',
      value: 'dataRecebimento',
      align: 'center',
      valueAlign: 'center',
      mapValue: v => DateHelpers.formatDate(v.dataPagamento),
      width: 140,
    },
  ];
}

function useAgendamentoQuery(props: IProps) {
  return useQueryConfig<
    IAgendamentoQuery,
    IAgendamentoFragment_transacoes[],
    IAgendamentoQueryVariables
  >({
    query: QueryGraphql.AgendamentoQuery,
    variables: () => ({ id: props.agendamentoId }),
    mapData: result => result?.agendamento?.transacoes || [],
  });
}

function emitRecebimentoStatusChanged({
  ctx,
  item,
  pago,
}: {
  ctx: SetupContext;
  item: IAgendamentoFragment_transacoes;
  pago: boolean;
}) {
  item.pago = pago;

  ctx.emit('recebimentoStatusChanged', { transacaoId: item.id, pago });
}
