import { MyPage } from '@/components/page/MyPage';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAgendaPage } from '@/lib/composables/page/useAgendaPage';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IAgendamentoFormModel } from '@/typings';
import { computed } from '@vue/composition-api';
import { AgendamentoForm } from '../components/forms/AgendamentoForm';

export default createComponent({
  name: 'AgendamentoFormPage',
  setup(props, ctx) {
    const { profissionalId } = useAgendaPage();

    const { page, handleSubmit, isEdit } = useFormPage<IAgendamentoFormModel>({
      ctx,
      async submitCallback({ recorrencia, ...model }) {
        if (page.id) {
          return AgendamentoService.update({
            id: page.id,
            profissionalId,
            model,
          });
        } else if (recorrencia?.repetir.repetir) {
          return AgendamentoService.createRecorrencia({
            profissionalId,
            model,
            recorrencia,
          });
        }

        return AgendamentoService.create({
          profissionalId,
          model,
        });
      },
    });

    const $title = computed(() =>
      isEdit ? 'Editar Agendamento' : 'Novo Agendamento',
    );

    async function handleDelete(
      id: string,
      { tipo: { tipo }, time: { data }, recorrenciaId }: IAgendamentoFormModel,
    ) {
      return AgendamentoService.delete({
        id,
        profissionalId,
        bloqueado: tipo === 'Horário bloqueado',
        dataFinal: data!,
        hasRecorrencia: !!recorrenciaId,
      });
    }

    return () => (
      <MyPage title={$title.value} form>
        <AgendamentoForm
          page={page}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      </MyPage>
    );
  },
});
