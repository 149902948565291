import { useValue } from '@/lib/composables';
import { useBreakpoints } from '@/lib/composables/utils/useBreakpoints';
import { CalendarHelpers, FCalendar } from '@/lib/helpers/calendar.helpers';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IAgendamentoFragment,
  ICalendarDateRange,
  ICalendarView,
  IConfiguracaoAgendaFragment,
  IDateRangeModel,
} from '@/typings';
import {
  Calendar as FullCalendar,
  CalendarOptions,
  DatesSetArg,
  EventApi,
  EventClickArg,
  EventDropArg,
  EventInput,
} from '@fullcalendar/core';
import interactionPlugin, {
  DateClickArg,
  EventResizeDoneArg,
} from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
  computed,
  ComputedRef,
  ref,
  Ref,
  SetupContext,
} from '@vue/composition-api';
import debounce from 'lodash/debounce';
import { DateTime } from 'luxon';
import { LoadingOverlay } from '../loading/LoadingOverlay';
import { PageSection } from '../page/PageSection';
import { CalendarHeaderRow } from './myCalendar/CalendarHeaderRow';

interface IProps {
  // model
  value?: IAgendamentoFragment[] | null;
  loading?: boolean;
  config: IConfiguracaoAgendaFragment;
}

interface IEvents {
  onInput: (events: EventInput[]) => void;
  onDatesChange: (range: IDateRangeModel | null) => void;
  onDateClick: (date: Date) => void;
  onEventClick: (event: EventApi) => void;
  onEventChange: (event: EventApi) => void;
}

export const MyCalendar = createComponent<IProps, IEvents>({
  name: 'MyCalendar',
  props: {
    value: { type: Array, default: () => [] },
    config: { type: Object, required: true },
    loading: { type: Boolean, default: false },
  },
  components: { FCalendar },
  setup(props, ctx) {
    const $fullCalendarEl = ref<any>(null);

    const { $view, setView, $range, setRange } = useData();

    const { $calendar, $events, $maxTime, $minTime } = useComputeds({
      props,
      $fullCalendarEl,
    });

    const actions = useActions({
      props,
      ctx,
      $calendar,
      setView,
      $range,
      setRange,
    });

    const { $options } = useOptions(
      props,
      $view,
      $events,
      $minTime,
      $maxTime,
      actions,
    );

    return () => (
      <PageSection>
        <CalendarHeaderRow
          view={$view.value}
          range={$range.value}
          onRangeChange={actions.handleRangeChange}
          onToggleView={actions.handleToggleView}
          onToday={actions.handleToday}
          onPrevious={actions.handlePrevious}
          onNext={actions.handleNext}
        />

        <v-divider class="mb-2" />

        <div id="MyCalendar" class="relative mb-4">
          <LoadingOverlay loading={!!props.loading} height={3} top={49} />

          <f-calendar ref={$fullCalendarEl} options={$options.value} />
        </div>
      </PageSection>
    );
  },
});

function useData() {
  const [$view, setView] = useValue<ICalendarView>('timeGridWeek');

  const [$range, setRange] = useValue<ICalendarDateRange | null>(null);

  return {
    $view,
    setView,
    $range,
    setRange,
  };
}

function useComputeds({
  props,
  $fullCalendarEl,
}: {
  props: IProps;
  $fullCalendarEl: Ref<any>;
}) {
  const $calendar = computed<FullCalendar | undefined>(() =>
    $fullCalendarEl.value?.getApi(),
  );

  const $minTime: ComputedRef<string> = computed(() =>
    CalendarHelpers.minTime({
      agendamentos: props.value || [],
      config: props.config,
    }),
  );

  const $maxTime: ComputedRef<string> = computed(() =>
    CalendarHelpers.maxTime({
      agendamentos: props.value || [],
      config: props.config,
    }),
  );

  const $events: ComputedRef<EventInput[]> = computed<EventInput[]>(() =>
    CalendarHelpers.mapEvents({
      agendamentos: props.value,
      config: props.config,
      maxTime: $maxTime.value,
      minTime: $minTime.value,
    }),
  );

  return {
    $calendar,
    $events,
    $minTime,
    $maxTime,
  };
}

function useOptions(
  props: IProps,
  $view: Ref<ICalendarView>,
  $events: ComputedRef<EventInput[]>,
  $minTime: ComputedRef<string>,
  $maxTime: ComputedRef<string>,
  actions: IActions,
) {
  const $options = computed<CalendarOptions>(() => ({
    plugins: [interactionPlugin, luxonPlugin, timeGridPlugin],
    initialView: $view.value,
    locale: 'pt-br',
    allDaySlot: false,
    stickyHeaderDates: true,
    height: 'auto',
    slotDuration: '00:15:00',
    slotLabelInterval: '00:15:00',
    snapDuration: '00:05:00',
    editable: true,
    eventTimeFormat: 'HH:mm',
    slotLabelFormat: 'HH:mm',
    headerToolbar: false,
    nowIndicator: true,
    businessHours: {
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      startTime: props.config.horaInicio,
      endTime: props.config.horaFim,
    },
    slotMinTime: $minTime.value,
    slotMaxTime: $maxTime.value,
    hiddenDays: CalendarHelpers.hiddenDays(props.config),
    dayHeaderContent: CalendarHelpers.dayHeaderContent,
    eventContent: CalendarHelpers.eventContent,
    eventConstraint: {
      startTime: $minTime.value,
      endTime: $maxTime.value,
    },
    events: $events.value as EventInput[],
    datesSet: actions.handleDatesSet,
    dateClick: actions.handleDateClick,
    eventClick: actions.handleEventClick,
    eventDrop: actions.handleEventDrop,
    eventResize: actions.handleEventResize,
  }));

  return { $options };
}

type IActions = ReturnType<typeof useActions>;
function useActions({
  props,
  ctx,
  $calendar,
  setView,
  $range,
  setRange,
}: {
  props: IProps;
  ctx: SetupContext;
  $calendar: ComputedRef<FullCalendar | undefined>;
  setView: (v: ICalendarView) => void;
  $range: Ref<ICalendarDateRange | null>;
  setRange: (v: ICalendarDateRange | null) => void;
}) {
  function emitEventChange(event: EventApi) {
    ctx.emit('eventChange', event);
  }

  function handleBreakpointChange() {
    const { $width } = useBreakpoints();

    if ($width.value < 700) {
      handleToggleView('timeGridDay');
    }
  }

  function handleToday() {
    $calendar.value?.today();
  }

  function handleNext() {
    $calendar.value?.next();
  }

  function handlePrevious() {
    $calendar.value?.prev();
  }

  function handleRangeChange(range: ICalendarDateRange | null) {
    if (!range) return;

    $calendar.value?.gotoDate(range.startDate);
  }

  function handleToggleView(view: ICalendarView) {
    setView(view);

    $calendar.value?.changeView(view);
  }

  function handleChangeRange({
    view,
    start,
    end,
  }: {
    view: ICalendarView;
    start: DateTime | null;
    end: DateTime | null;
  }) {
    if (!start || !end) return;

    if (view === 'timeGridDay') {
      setRange({
        startDate: DateHelpers.toISODate(start)!,
        endDate: DateHelpers.toISODate(start)!,
      });
    } else if (view === 'timeGridWeek') {
      setRange({
        startDate: DateHelpers.toISODate(start)!,
        endDate: DateHelpers.toISODate(end)!,
      });
    }

    ctx.emit('datesChange', $range.value);
  }

  function handleDatesSet({ view }: DatesSetArg) {
    handleChangeRange({
      view: view.type as ICalendarView,
      start: DateHelpers.dateToUTC(view.activeStart),
      end: DateHelpers.dateToUTC(view.activeEnd)?.minus({ day: 1 }) || null,
    });
  }

  function handleDateClick({ date }: DateClickArg) {
    if (
      CalendarHelpers.dateInAlmoco(date, props.config) ||
      CalendarHelpers.dateInDiaBloqueado(date, props.config)
    ) {
      return;
    }

    ctx.emit('dateClick', DateHelpers.dateMinuteSlots(date));
  }

  function handleEventClick({ event }: EventClickArg) {
    if (event.display === 'background') {
      return;
    }

    ctx.emit('eventClick', event);
  }

  const debouncedEventChange = debounce(
    (event: EventApi) => emitEventChange(event),
    250,
  );

  function handleEventDrop({ event }: EventDropArg) {
    debouncedEventChange(event);
  }

  function handleEventResize({ event }: EventResizeDoneArg) {
    debouncedEventChange(event);
  }

  const { $width } = useBreakpoints();

  watchRun($width, handleBreakpointChange);

  return {
    emitEventChange,
    handleBreakpointChange,
    handleToday,
    handleNext,
    handlePrevious,
    handleRangeChange,
    handleToggleView,
    handleChangeRange,
    handleDatesSet,
    handleDateClick,
    handleEventClick,
    handleEventDrop,
    handleEventResize,
    debouncedEventChange,
  };
}
