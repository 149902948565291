import {
  AgendamentoStatus,
  IAgendamentoFragment,
  ICalendarEventExtendedProps,
  IConfiguracaoAgendaFragment,
} from '@/typings';
import {
  DayHeaderContentArg,
  EventContentArg,
  EventInput,
} from '@fullcalendar/core';
import FCalendar from '@fullcalendar/vue';
import isNumber from 'lodash/isNumber';
import upperFirst from 'lodash/upperFirst';
import { ConstantsHelper } from '../constants/helper';
import { DateHelpers } from './date.helpers';
import { MyTheme } from './MyTheme';
import { hexToFontColor } from './utils';

// Imported and exported here, to be imported first in MyCalendar.tsx
// because of vscode's organize imports
export { FCalendar };

export const CalendarHelpers = {
  daysOfWeek(config: IConfiguracaoAgendaFragment, hidden = false): number[] {
    const { domingo, segunda, terca, quarta, quinta, sexta, sabado } = config;

    return [domingo, segunda, terca, quarta, quinta, sexta, sabado]
      .map((v, idx) => {
        const pred = hidden ? v : !v;
        return pred ? null : idx;
      })
      .filter(isNumber);
  },

  hiddenDays(config: IConfiguracaoAgendaFragment): number[] {
    if (!config.mostrarDiasBloqueados) {
      return CalendarHelpers.daysOfWeek(config, true);
    }

    return [];
  },

  mapEvents({
    config,
    agendamentos,
    maxTime,
    minTime,
  }: {
    agendamentos: IAgendamentoFragment[] | null | undefined;
    config: IConfiguracaoAgendaFragment;
    maxTime: string;
    minTime: string;
  }): EventInput[] {
    const diasBloqueados: EventInput[] = [];
    if (config.mostrarDiasBloqueados) {
      diasBloqueados.push({
        title: 'Dia bloqueado',
        daysOfWeek: CalendarHelpers.daysOfWeek(config, true),
        startTime: minTime,
        endTime: maxTime,
        display: 'background',
        classNames: 'dia-bloqueado',
      });
    }

    const almoco: EventInput[] = [];
    if (config.horarioAlmoco) {
      almoco.push({
        title: 'Horário de almoço',
        daysOfWeek: CalendarHelpers.daysOfWeek(config),
        startTime: config.horaAlmocoInicio,
        endTime: config.horaAlmocoFim,
        color: MyTheme.gray400,
        display: 'background',
        classNames: 'almoco',
      });
    }

    const eventos: EventInput[] = (agendamentos || []).map(v => {
      if (v.bloqueado) {
        const eventBloqueado = {
          id: v.id,
          title: 'Horário bloqueado',
          allDay: false,
          horarioBloqueado: true,
          classNames: CalendarHelpers.eventClassNames(v),
          agendamento: v,
        };

        if (v.diaTodo) {
          return {
            ...eventBloqueado,
            start: `${v.data}T${minTime}`,
            end: `${v.data}T${maxTime}`,
            diaTodo: true,
            durationEditable: false,
          };
        }

        return {
          ...eventBloqueado,
          start: `${v.data}T${v.horaInicial}`,
          end: `${v.data}T${v.horaFinal}`,
          diaTodo: false,
        };
      }

      const procedimentos = v.procedimentos!;

      const event = {
        id: v.id,
        title: v.paciente!.nome,
        start: `${v.data}T${v.horaInicial}`,
        end: `${v.data}T${v.horaFinal}`,
        allDay: false,
        horarioBloqueado: false,
        diaTodo: false,
        agendamento: v,
        classNames: CalendarHelpers.eventClassNames(v),
        editable: v.status !== AgendamentoStatus.PACIENTE_ATENDIDO,
      };

      if (procedimentos.length > 1) {
        return {
          ...event,
          color: MyTheme.blue100,
          borderColor: MyTheme.blue400,
          textColor: MyTheme.blue900,
          colors: procedimentos!.map(v => v.procedimento.cor),
        };
      }

      const color = procedimentos[0].procedimento.cor;
      return {
        ...event,
        color,
        textColor: hexToFontColor(color),
        colors: null,
      };
    });

    return [...eventos, ...diasBloqueados, ...almoco];
  },

  eventClassNames({
    bloqueado,
    diaTodo,
    status,
    procedimentos,
  }: IAgendamentoFragment): string[] {
    if (bloqueado) {
      return diaTodo
        ? ['horario-bloqueado', 'dia-todo']
        : ['horario-bloqueado'];
    }
    const classNames: string[] = [];

    if (status !== AgendamentoStatus.AGENDADO) {
      classNames.push('has-status-icon');
    }

    if (procedimentos && procedimentos.length > 0) {
      classNames.push('has-procedimentos-list');
    }

    if (status === AgendamentoStatus.PACIENTE_ATENDIDO) {
      classNames.push('done');
    }

    return classNames;
  },

  dayHeaderContent({ date }: DayHeaderContentArg) {
    const value = DateHelpers.dateToUTC(date);

    const weekday = upperFirst(
      (DateHelpers.formatWeekday(value) || '').split('-')[0],
    );
    const dayMonth = DateHelpers.formatDayAndMonth(value);

    return {
      html: `
        <div class="weekday">${weekday}</div>
        <div class="day-month">${dayMonth}</div>
      `,
    };
  },

  eventContent({ event }: EventContentArg) {
    if (event.display === 'background') {
      return event.title;
    }

    const extendedProps =
      event.extendedProps as ICalendarEventExtendedProps | null;

    let iconHTML = '';
    let colorsHTML = '';

    if (extendedProps) {
      const { agendamento, horarioBloqueado, colors } = extendedProps;
      if (agendamento) {
        const status = CalendarHelpers.agendamentoStatus(agendamento);

        if (status && status.value !== AgendamentoStatus.AGENDADO) {
          // status icon
          iconHTML = `<div
            aria-label="${status.label}"
            data-balloon-pos="up"
          >
            <span
              class="v-icon notranslate v-icon--svg theme--light"
              style="color: ${event.textColor}; caret-color: ${event.textColor};"
            >
              <svg
                viewBox="0 0 24 24"
                height="18"
                width="18"
                class="fill-current"
              >
                <path d="${status.icon}"></path>
              </svg>
            </span>
          </div>`;
        }
      }

      if (!horarioBloqueado && colors) {
        const colorsDivs = colors
          .map(
            v =>
              `<div class="procedimento" style="background-color:${v}"></div>`,
          )
          .join('');

        colorsHTML = `<div class="procedimentos-list">${colorsDivs}</div>`;
      }
    }

    const startHour = DateHelpers.formatHour(event.start);
    const endHour = DateHelpers.formatHour(event.end);

    return {
      html: `
        <div class="fc-event-main-frame">
          <div class="fc-event-time">${startHour} - ${endHour}</div>
          ${iconHTML}
          <div class="fc-event-title-container">
            <div class="fc-event-title fc-sticky">${event.title}</div>
          </div>
          ${colorsHTML}
        </div>
      `,
    };
  },

  minTime({
    agendamentos,
    config,
  }: {
    agendamentos: IAgendamentoFragment[];
    config: IConfiguracaoAgendaFragment;
  }): string {
    const minHoras = agendamentos
      .filter(f => !f.diaTodo)
      .map(v => DateHelpers.parse(v.horaInicial))
      .filter(Boolean)
      .map(v => v!.hour)
      .sort((a, b) => a - b);

    if (!minHoras.length) return config.horaInicio;

    const minHora = minHoras[0];
    const horaInicio = DateHelpers.parse(config.horaInicio)!.hour;

    if (minHora < horaInicio) {
      return DateHelpers.today()
        .set({ hour: minHora, minute: 0, second: 0 })
        .toISOTime()!;
    }

    return config.horaInicio;
  },

  maxTime({
    agendamentos,
    config,
  }: {
    agendamentos: IAgendamentoFragment[];
    config: IConfiguracaoAgendaFragment;
  }): string {
    const maxHoras = agendamentos
      .filter(f => !f.diaTodo)
      .map(v => DateHelpers.parse(v.horaFinal))
      .filter(Boolean)
      .map(v => v!.hour)
      .sort((a, b) => b - a);

    if (!maxHoras.length) return config.horaFim;

    const maxHora = maxHoras[0];
    const horaFinal = DateHelpers.parse(config.horaFim)!.hour;

    if (maxHora > horaFinal) {
      return DateHelpers.today()
        .set({ hour: maxHora, minute: 59, second: 59 })
        .toISOTime()!;
    }

    return config.horaFim;
  },

  dateInAlmoco(date: Date, config: IConfiguracaoAgendaFragment) {
    const { horarioAlmoco, horaAlmocoInicio, horaAlmocoFim } = config;

    if (!horarioAlmoco) return false;

    const value = DateHelpers.parse(date)!;
    const isoDate = value.toISODate();

    const almocoInicio = DateHelpers.parse(`${isoDate}T${horaAlmocoInicio}`)!;
    const almocoFim = DateHelpers.parse(`${isoDate}T${horaAlmocoFim}`)!;

    if (value < almocoInicio || value > almocoFim) {
      return false;
    } else if (CalendarHelpers.luxonWeekdays(config).includes(value.weekday)) {
      return true;
    }
  },

  luxonWeekdays(config: IConfiguracaoAgendaFragment) {
    const { segunda, terca, quarta, quinta, sexta, sabado, domingo } = config;

    return [segunda, terca, quarta, quinta, sexta, sabado, domingo]
      .map((v, idx) => (v ? idx + 1 : null))
      .filter(isNumber);
  },

  dateInDiaBloqueado(date: Date, config: IConfiguracaoAgendaFragment) {
    const { mostrarDiasBloqueados } = config;

    if (!mostrarDiasBloqueados) return false;

    const { weekday } = DateHelpers.parse(date)!;

    return !CalendarHelpers.luxonWeekdays(config).includes(weekday);
  },

  agendamentoStatus(agendamento: IAgendamentoFragment) {
    return ConstantsHelper.agendamentoStatus.find(
      f => f.value === agendamento.status,
    );
  },
};
