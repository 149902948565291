import { AgendamentoService } from '../../services';
import { useRouteParams } from '../utils/useRouter';

export function useAgendaPage() {
  const { profissionalId } = useRouteParams();

  AgendamentoService.loadAgenda({ profissionalId });

  return { profissionalId };
}
