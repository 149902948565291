import { PageSection } from '@/components/page/PageSection';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';

interface IProps {
  hasParcelas: boolean;
}

interface IEvents {
  onCancel: () => any;
  onSubmit: () => any;
}

export const AgendamentoRecebimentoFormActionsRow = createComponent<
  IProps,
  IEvents
>({
  name: 'AgendamentoRecebimentoFormActionsRow',
  props: {
    hasParcelas: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    function emitSubmit() {
      ctx.emit('submit');
    }

    function emitCancel() {
      ctx.emit('cancel');
    }
    return () => (
      <PageSection divider slot="actions_section">
        <div class="flex">
          <v-btn text onClick={emitCancel}>
            <v-icon>{MyIcons.back}</v-icon>
            Voltar
          </v-btn>

          <v-spacer />

          {!props.hasParcelas && (
            <v-btn color="primary" onClick={emitSubmit}>
              Salvar recebimento
            </v-btn>
          )}
        </div>
      </PageSection>
    );
  },
});
