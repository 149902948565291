import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { ExpandTransition } from '@/components/utils/ExpandTransition';
import { ConstantsHelper } from '@/lib/constants/helper';
import { validationSummary } from '@/lib/form';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IAgendamentoRecorrenciaFormModel,
  IAgendamentoRecorrenciaFormSchema,
  IForm,
  IFormValidationErrors,
  IRequireField,
  TipoRepeticao,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IAgendamentoRecorrenciaFormModel;
  form: IForm<any>;
  hide?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (recorrencia: IAgendamentoRecorrenciaFormModel[]) => void;
  onValidation: (errors: IFormValidationErrors[]) => void;
}

export const AgendamentoRecorrenciaFields = createComponent<IJsxProps, IEvents>(
  {
    name: 'AgendamentoRecorrenciaFields',
    props: {
      value: { type: Object, required: true },
      form: { type: Object, required: true },
    },
    setup(props: IProps, ctx) {
      const {
        $showSemana,
        $repetir,
        $diasSemanaHasError,
        $terminaEmHasError,
        $schema,
      } = useComputeds(props);

      const { handleInput } = useEvents({
        props,
        ctx,
        $schema,
        $diasSemanaHasError,
      });

      return () => (
        <PageSection divider title="Repetição">
          <div class="flex flex-col" slot="fields">
            <RepetirFields
              form={props.form}
              schema={$schema.value}
              value={props.value}
              handleInput={handleInput}
            />

            <DiasSemanaFields
              form={props.form}
              schema={$schema.value}
              value={props.value}
              handleInput={handleInput}
              hide={$showSemana.value}
              error={$diasSemanaHasError.value}
            />

            <TerminaEmFields
              form={props.form}
              schema={$schema.value}
              value={props.value}
              handleInput={handleInput}
              hide={$repetir.value}
              error={$terminaEmHasError.value}
            />
          </div>
        </PageSection>
      );
    },
  },
);

function useComputeds(props: IProps) {
  const $showSemana = computed(
    () =>
      props.value.repetir.repetir &&
      props.value.repetir.tipo === TipoRepeticao.SEMANALMENTE,
  );

  const $repetir = computed(() => props.value.repetir.repetir);

  const $requireOcorrencias = computed(
    () =>
      props.value.repetir.repetir &&
      props.value.terminaEm.tipo === 'ocorrencias',
  );

  const $requireDataFinal = computed(
    () =>
      props.value.repetir.repetir && props.value.terminaEm.tipo === 'dataFinal',
  );

  const $diasSemanaHasError = computed(() => {
    if (!$showSemana.value || !props.form.submitted) {
      return false;
    }

    const d = props.value.diasSemana;

    return (
      !d.domingo &&
      !d.segunda &&
      !d.terca &&
      !d.quarta &&
      !d.quinta &&
      !d.sexta &&
      !d.sabado
    );
  });

  const $terminaEmHasError = computed(
    () =>
      props.form.submitted &&
      (!props.value.terminaEm.tipo ||
        (!props.value.terminaEm.ocorrencias &&
          !props.value.terminaEm.dataFinal)),
  );

  const $schema = computed<IAgendamentoRecorrenciaFormSchema>(() => ({
    repetir: {
      repetir: {
        label: 'Repetir',
        type: 'switch',
        layout: { width: 140 },
      },
      tipo: {
        label: 'Frequência',
        type: 'select',
        items: ConstantsHelper.tiposRepeticoes,
        hidden: !$repetir.value,
        validations: {
          required: $repetir.value,
        },
        layout: { width: 200 },
      },
    },
    diasSemana: {
      domingo: {
        label: 'Domingo',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      segunda: {
        label: 'Segunda',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      terca: {
        label: 'Terça',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      quarta: {
        label: 'Quarta',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      quinta: {
        label: 'Quinta',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      sexta: {
        label: 'Sexta',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      sabado: {
        label: 'Sábado',
        type: 'checkbox',
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
    },
    terminaEm: {
      tipo: {
        label: 'Termina',
        type: 'select',
        items: ConstantsHelper.agendamentoRecorrenciaTerminaEmTipos,
        validations: {
          required: $repetir.value,
        },
        layout: { width: 200 },
      },
      ocorrencias: {
        label: 'Ocorrências',
        type: 'number',
        integer: true,
        disabled: !$requireOcorrencias.value,
        validations: {
          required: $requireOcorrencias.value,
          gt: 0,
          lte: 400,
          customName: 'Termina em Ocorrências',
        },
        layout: {
          sm: 3,
          minWidth: 120,
        },
      },
      dataFinal: {
        label: 'Data final',
        type: 'date',
        disabled: !$requireDataFinal.value,
        validations: {
          required: $requireDataFinal.value,
          customName: 'Termina em Data final',
          maxDate: DateHelpers.today().plus({ year: 1 }),
          minDate: DateHelpers.today().plus({ day: 1 }),
        },
        layout: { sm: 4 },
      },
    },
  }));

  return {
    $showSemana,
    $repetir,
    $requireOcorrencias,
    $requireDataFinal,
    $diasSemanaHasError,
    $terminaEmHasError,
    $schema,
  };
}

function useEvents({
  props,
  ctx,
  $schema,
  $diasSemanaHasError,
}: {
  props: IProps;
  ctx: SetupContext;
  $schema: Ref<IAgendamentoRecorrenciaFormSchema>;
  $diasSemanaHasError: Ref<boolean>;
}) {
  function emitValidation() {
    const errors = validationSummary({
      schema: $schema.value,
      model: props.value,
      form: { submitted: true },
      customErrors: [],
    });

    if ($diasSemanaHasError.value) {
      errors.push({
        label: 'Dias da semana',
        error: 'selecione ao menos um dia da semana',
      });
    }

    ctx.emit('validation', errors);
  }

  function handleInput(
    key: keyof IAgendamentoRecorrenciaFormModel,
    value: any,
  ) {
    props.value[key] = value;

    emitInput(props.value);

    emitValidation();
  }

  function emitInput(recorrencia: IAgendamentoRecorrenciaFormModel) {
    ctx.emit('input', recorrencia);
  }

  watchRun(() => props.form.submitted, emitValidation);

  watchRun(
    () => props.value.terminaEm.tipo,
    tipo => {
      if (tipo === 'ocorrencias') {
        props.value.terminaEm.dataFinal = null;
      } else if (tipo === 'dataFinal') {
        props.value.terminaEm.ocorrencias = null;
      } else {
        props.value.terminaEm.dataFinal = null;
        props.value.terminaEm.ocorrencias = null;
      }
    },
  );

  return { handleInput };
}

const title = (text: string, error: boolean) => {
  const errorClass = error ? 'text-error' : 'text-coolGray-700';

  return <h3 class={['font-normal', errorClass]}>{text}</h3>;
};

interface IFieldsProps {
  form: IForm<any>;
  schema: IAgendamentoRecorrenciaFormSchema;
  value: IAgendamentoRecorrenciaFormModel;
  handleInput: (
    key: keyof IAgendamentoRecorrenciaFormModel,
    value: any,
  ) => void;
}

const RepetirFields = createComponent<IFieldsProps>({
  name: 'AgendamentoRecorrenciaFieldsRepetirFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    value: { type: Object, required: true },
    handleInput: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => (
      <FormFields
        form={props.form}
        schema={props.schema.repetir}
        value={props.value.repetir}
        onInput={value => props.handleInput('repetir', value)}
      />
    );
  },
});

const DiasSemanaFields = createComponent<
  IFieldsProps & {
    hide: boolean;
    error: boolean;
  }
>({
  name: 'AgendamentoRecorrenciaFieldsRepetirFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    value: { type: Object, required: true },
    handleInput: { type: Function, required: true },
    hide: { type: Boolean, required: true },
    error: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    return () => (
      <ExpandTransition hide={!props.hide}>
        {title('Dias da semana', props.error)}

        <FormFields
          form={props.form}
          schema={props.schema.diasSemana}
          value={props.value.diasSemana}
          onInput={value => props.handleInput('diasSemana', value)}
          class="mb-6 ml-4"
        />
      </ExpandTransition>
    );
  },
});

const TerminaEmFields = createComponent<
  IFieldsProps & {
    hide: boolean;
    error: boolean;
  }
>({
  name: 'AgendamentoRecorrenciaFieldsTerminaEmFields',
  props: {
    form: { type: Object, required: true },
    schema: { type: Object, required: true },
    value: { type: Object, required: true },
    handleInput: { type: Function, required: true },
    hide: { type: Boolean, required: true },
    error: { type: Boolean, required: true },
  },
  setup(props, ctx) {
    return () => (
      <ExpandTransition hide={!props.hide}>
        {title('Termina em', props.error)}

        <FormFields
          form={props.form}
          schema={props.schema.terminaEm}
          value={props.value.terminaEm}
          onInput={value => props.handleInput('terminaEm', value)}
          class="ml-4"
        />
      </ExpandTransition>
    );
  },
});
