import {
  IFetchMoreSearchQuery,
  ListFetchMore,
} from '@/components/data/ListFetchMore';
import { MyDraggable } from '@/components/data/MyDraggable';
import { CircularLoading } from '@/components/loading/CircularLoading';
import { FormHeader } from '@/components/typography/FormHeader';
import { QueryGraphql } from '@/graphql/query';
import { useValue } from '@/lib/composables';
import { useRouter } from '@/lib/composables/utils/useRouter';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ListaEsperaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  IListaEsperaFragment,
  IListasEsperasQuery,
  IListasEsperasQueryVariables,
  ListaEsperaOrderBy,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';
import { ListaEsperaPacientesItem } from './ListaEsperaPacientesItem';

interface IProps {
  profissionalId: string;
}

interface IEvents {
  onReorder: (items: IListaEsperaFragment[]) => void;
  onOrdemChange: (ordem: number) => void;
}

export const ListaEsperaPacientes = createComponent<IProps, IEvents>({
  name: 'ListaEsperaPacientes',
  props: {
    profissionalId: { type: String, required: true },
  },
  setup(props, ctx) {
    const {
      $items,
      setItems,
      $ordem,
      setOrdem,
      $initialLoading,
      setInitialLoading,
    } = useData();

    const { handleRemove, handleAgendar, emitReorder, emitOrdemChange } =
      useEvents({
        props,
        ctx,
        $items,
        setItems,
        $ordem,
        setOrdem,
      });

    const { $query, $agendaRoute } = useComputeds({
      props,
      setInitialLoading,
      emitOrdemChange,
    });

    return () => (
      <div class="flex flex-col w-full sm:w-1/2">
        <FormHeader
          title="Lista de Pacientes"
          class="flex-none mx-4 mt-4 mb-2"
          noPadding
        />

        <div
          class="flex flex-col px-4 pt-2 overflow-y-auto"
          style="height: 444px; max-height: 444px;"
        >
          <ListFetchMore config={$query.value} onItemsChange={setItems}>
            <CircularLoading
              loading={$initialLoading.value}
              size={50}
              hideOnLeave
            >
              <DraggableList
                items={$items.value}
                emitReorder={emitReorder}
                handleAgendar={handleAgendar}
                handleRemove={handleRemove}
              />
            </CircularLoading>
          </ListFetchMore>
        </div>

        <div class="flex flex-initial mx-4 mt-auto mb-4">
          <v-btn text exact to={$agendaRoute.value}>
            <v-icon>{MyIcons.back}</v-icon>
            Voltar
          </v-btn>
        </div>
      </div>
    );
  },
});

function useData() {
  const [$items, setItems] = useValue<IListaEsperaFragment[]>([]);

  const [$ordem, setOrdem] = useValue(0);

  const [$initialLoading, setInitialLoading] = useValue(true);

  return {
    $items,
    setItems,
    $ordem,
    setOrdem,
    $initialLoading,
    setInitialLoading,
  };
}

function useComputeds({
  props,
  setInitialLoading,
  emitOrdemChange,
}: {
  props: IProps;
  setInitialLoading: (v: boolean) => void;
  emitOrdemChange: (ordem?: number | undefined) => void;
}) {
  const $query = computed<IFetchMoreSearchQuery>(() => ({
    query: QueryGraphql.ListasEsperasQuery,
    name: 'listasEsperas',
    variables: (): IListasEsperasQueryVariables => {
      return {
        profissionalId: props.profissionalId,
        orderBy: [ListaEsperaOrderBy.ordem_ASC, ListaEsperaOrderBy.nome_ASC],
        take: 3,
      };
    },
    mapData: (result?: IListasEsperasQuery) => {
      emitOrdemChange(result?.listasEsperas.pageInfo.count);

      setInitialLoading(false);

      return result?.listasEsperas.nodes;
    },
    mapPageInfo: (result: IListasEsperasQuery) =>
      result?.listasEsperas.pageInfo,
  }));

  const $agendaRoute = computed(
    () => Routes.app.agenda(props.profissionalId).index,
  );

  return { $query, $agendaRoute };
}

function useEvents({
  props,
  ctx,
  $items,
  setItems,
  $ordem,
  setOrdem,
}: {
  props: IProps;
  ctx: SetupContext;
  $items: Ref<IListaEsperaFragment[]>;
  setItems: (v: IListaEsperaFragment[]) => void;
  $ordem: Ref<number>;
  setOrdem: (v: number) => void;
}) {
  function handleRemove(item: IListaEsperaFragment) {
    setItems($items.value.filter(f => f?.id !== item.id));

    return ListaEsperaService.delete(item.id);
  }

  function handleAgendar(item: IListaEsperaFragment) {
    useRouter().push(
      Routes.app.agenda(props.profissionalId).agendamentos.new({
        listaEsperaId: item.id,
      }),
    );
  }

  function emitReorder(items: IListaEsperaFragment[]) {
    setItems(items);

    ctx.emit('reorder', items);
  }

  function emitOrdemChange(ordem?: number) {
    setOrdem(ordem || 0);

    ctx.emit('ordemChange', $ordem.value);
  }

  return {
    handleRemove,
    handleAgendar,
    emitReorder,
    emitOrdemChange,
  };
}

interface IDraggableListProps {
  items: IListaEsperaFragment[];
  emitReorder: (items: IListaEsperaFragment[]) => void;
  handleRemove: (item: IListaEsperaFragment) => Promise<void>;
  handleAgendar: (item: IListaEsperaFragment) => void;
}

const DraggableList = createComponent<IDraggableListProps>({
  name: 'ListaEsperaPacientesDraggableList',
  props: {
    items: { type: Array, required: true },
    emitReorder: { type: Function, required: true },
    handleRemove: { type: Function, required: true },
    handleAgendar: { type: Function, required: true },
  },
  setup(props, ctx) {
    return () => {
      if (!props.items || !props.items.length) {
        return null;
      }

      return (
        <MyDraggable value={props.items} onInput={props.emitReorder}>
          {props.items.map(
            (item: IListaEsperaFragment | undefined, idx) =>
              item && (
                <ListaEsperaPacientesItem
                  class="mb-4"
                  key={item.id}
                  item={item}
                  ordem={idx + 1}
                  onRemove={props.handleRemove}
                  onAgendar={props.handleAgendar}
                />
              ),
          )}
        </MyDraggable>
      );
    };
  },
});
