import { FormFields } from '@/components/form/fields/FormFields';
import { MyForm } from '@/components/form/MyForm';
import { FormHeader } from '@/components/typography/FormHeader';
import { useState } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import { LookupsConfigs } from '@/lib/form/lookups';
import { getPacienteConvenio } from '@/lib/helpers/models/paciente';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { ConvenioService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import { IFormSchema, IListaEsperaModel, IPacienteFragment } from '@/typings';
import { computed, onMounted, SetupContext } from '@vue/composition-api';
import isString from 'lodash/isString';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IListaEsperaModel> {}

export const ListaEsperaForm = createComponent<IProps, IEvents>({
  name: 'ListaEsperaForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const $profissionalId = useState(s => s.agenda.profissionalId);

    const $indexRoute = computed(
      () =>
        $profissionalId.value && Routes.app.agenda($profissionalId.value).index,
    );

    const { $form, $schema, emitSubmit } = useForm(props, ctx);

    async function loadConvenioDefault() {
      const particular = await ConvenioService.getParticular();
      if (particular) {
        $form.value.model.convenioId = particular.id;
      }
    }

    onMounted(() => loadConvenioDefault());

    return () => (
      <MyForm
        noDivider
        noSummary
        form={$form.value}
        cancelTo={$indexRoute.value}
        onSubmit={emitSubmit}
      >
        <div class="flex flex-col p-4">
          <FormHeader title="Adicionar à lista" class="mb-4" noPadding />

          <FormFields
            form={$form.value}
            schema={$schema.value}
            v-model={$form.value.model}
            class="max-w-lg"
          />
        </div>

        <div slot="actions_section" class="flex mx-4 mb-4">
          <v-btn color="primary" block type="submit">
            <v-icon left>{MyIcons.listaEsperaAdd}</v-icon>
            Adicionar paciente
          </v-btn>
        </div>
      </MyForm>
    );
  },
});
function useForm(props: IProps, ctx: SetupContext) {
  return useFormConfig<IListaEsperaModel, IFormSchema<IListaEsperaModel>>({
    page: props.page,
    initialValue: {
      paciente: null,
      convenioId: null,
      email: null,
      celular: null,
      telefoneCasa: null,
      observacao: null,
    },
    mapSchema: (model, $form) => {
      const requireFone = !model.celular && !model.telefoneCasa;
      return {
        paciente: {
          label: 'Paciente',
          type: 'combobox',
          itemLabel: 'nome',
          lookup: LookupsConfigs.paciente({
            onModelChange(value: string | IPacienteFragment | null) {
              if (!value) {
                $form.value.model.celular = null;
                $form.value.model.telefoneCasa = null;
                $form.value.model.email = null;
                return;
              }

              if (isString(value)) return;

              $form.value.model.celular = value.celular;
              $form.value.model.telefoneCasa = value.telefoneCasa;
              $form.value.model.email = value.email;
              $form.value.model.convenioId = getPacienteConvenio(value);
            },
          }),
          validations: {
            required: true,
          },
        },
        convenioId: {
          label: 'Convênio',
          type: 'autocomplete',
          itemLabel: 'nome',
          lookup: LookupsConfigs.convenio(),
        },
        celular: {
          label: 'Celular',
          type: 'text',
          mask: 'celular',
          layout: {
            lg: 6,
            maxWidth: 260,
          },
          validations: {
            required: requireFone,
          },
        },
        telefoneCasa: {
          label: 'Telefone casa',
          type: 'text',
          mask: 'telefone',
          layout: {
            lg: 6,
            maxWidth: 260,
          },
          validations: {
            required: requireFone,
          },
        },
        email: {
          label: 'Email',
          type: 'email',
          validations: {
            email: true,
          },
        },
        observacao: {
          label: 'Observação',
          type: 'textarea',
          hideDetails: true,
        },
      };
    },
    ctx,
  });
}
