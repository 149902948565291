import { Alert } from '@/components/alerts/Alert';
import { BtnSmall } from '@/components/buttons/BtnSmall';
import { FormFields } from '@/components/form/fields/FormFields';
import { CircularLoading } from '@/components/loading/CircularLoading';
import { PageSection } from '@/components/page/PageSection';
import { useState, useValue } from '@/lib/composables';
import { LookupsConfigs } from '@/lib/form/lookups';
import { uuid } from '@/lib/helpers/uuid';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IAgendamentoProcedimentoModel,
  IConfiguracaoAgendaFragment_procedimentoPadrao,
  IForm,
  IFormSchema,
  IRequireField,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  // model
  value?: IAgendamentoProcedimentoModel[];
  form: IForm<any>;
  hasRecebimentos: boolean;
  hide?: boolean;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (procedimentos: IAgendamentoProcedimentoModel[]) => void;
}

export const AgendamentoProcedimentosFields = createComponent<
  IJsxProps,
  IEvents
>({
  name: 'AgendamentoProcedimentosFields',
  props: {
    value: { type: Array, required: true },
    hasRecebimentos: { type: Boolean, required: true },
    hide: { type: Boolean, default: false },
    form: { type: Object, required: true },
  },
  setup(props: IProps, ctx) {
    const [$lookupCount, setLookupCount] = useValue(0);

    const {
      $procedimentoPadrao,
      getSchema,
      $isLoading,
      $showRemoveBtn,
      showAddBtn,
    } = useComputeds({
      props,
      ctx,
      $lookupCount,
      setLookupCount,
    });

    const { handleAdd, handleRemove, handleInput } = useEvents({
      props,
      ctx,
      $procedimentoPadrao,
    });

    return () => (
      <PageSection divider title="Procedimentos" hide={props.hide}>
        <CircularLoading
          slot="fields"
          loading={$isLoading.value}
          size={60}
          noPadding
        >
          <div class="flex flex-col">
            <Alert
              text="Este agendamento já foi lançado no financeiro"
              type="warning"
              show={props.hasRecebimentos}
              dismissible={false}
            />

            {props.value.map(({ id }, index) => [
              index > 0 && <v-divider class="mb-4 dashed" />,

              procedimentoField({
                id,
                index,
                props,
                $showRemoveBtn,
                getSchema,
                handleAdd,
                handleRemove,
                handleInput,
                showAddBtn,
              }),
            ])}
          </div>
        </CircularLoading>
      </PageSection>
    );
  },
});

function useComputeds({
  props,
  ctx,
  $lookupCount,
  setLookupCount,
}: {
  props: IProps;
  ctx: SetupContext;
  $lookupCount: Ref<number>;
  setLookupCount: (v: number) => void;
}) {
  const $profissionalId = useState(s => s.agenda.profissionalId);

  const $config = useState(s => s.agenda.config);

  const $procedimentoPadrao = computed(() => $config.value?.procedimentoPadrao);

  function getSchema(
    index: number,
  ): IFormSchema<IAgendamentoProcedimentoModel> {
    return {
      procedimentoId: {
        label: 'Procedimento',
        type: 'autocomplete',
        itemLabel: 'nome',
        disabled: props.hasRecebimentos,
        lookup: LookupsConfigs.procedimento({
          profissionalId: $profissionalId.value,
          mapData(data) {
            setLookupCount(data.procedimentos.nodes.length);

            return data.procedimentos.nodes.filter(
              p =>
                !props.value.find(f => f.procedimentoId === p.id) ||
                p.id === props.value[index].procedimentoId,
            );
          },
          onModelChange(model) {
            if (model) {
              props.value[index].duracaoMinutos = model.duracaoMinutos;
            }
          },
        }),
        validations: {
          required: true,
        },
        layout: {
          xs: 9,
          minWidth: 300,
        },
      },
      quantidade: {
        label: 'Quantidade',
        type: 'number',
        integer: true,
        disabled: props.hasRecebimentos,
        validations: {
          required: true,
          gt: 0,
          lte: 99,
        },
        layout: {
          xs: 3,
          minWidth: 110,
        },
      },
    };
  }

  const $isLoading = computed(() => props.value.length === 0);

  const $showRemoveBtn = computed(() => props.value.length > 1);

  function showAddBtn(index: number) {
    return (
      index + 1 === props.value.length &&
      props.value.length < 4 &&
      props.value.length < $lookupCount.value &&
      !!props.value[index].procedimentoId
    );
  }

  return {
    $profissionalId,
    $config,
    $procedimentoPadrao,
    getSchema,
    $isLoading,
    $showRemoveBtn,
    showAddBtn,
  };
}

function useEvents({
  props,
  ctx,
  $procedimentoPadrao,
}: {
  props: IProps;
  ctx: SetupContext;
  $procedimentoPadrao: Ref<
    IConfiguracaoAgendaFragment_procedimentoPadrao | undefined
  >;
}) {
  function addDefault() {
    const isCreate = !props.form.page.id;

    if (isCreate && $procedimentoPadrao.value && !props.value.length) {
      return emitInput([
        {
          id: uuid(),
          procedimentoId: $procedimentoPadrao.value.id,
          quantidade: 1,
          duracaoMinutos: $procedimentoPadrao.value.duracaoMinutos,
        },
      ]);
    }
  }

  function handleAdd() {
    emitInput([
      ...props.value,
      {
        id: uuid(),
        procedimentoId: null,
        quantidade: 1,
        duracaoMinutos: 0,
      },
    ]);
  }

  function handleRemove(id: string | undefined) {
    emitInput(props.value.filter(v => v.id !== id));
  }

  function handleInput() {
    emitInput(props.value);
  }

  function emitInput(procedimentos: IAgendamentoProcedimentoModel[]) {
    ctx.emit('input', procedimentos);
  }

  watchRun(
    () => props.value.length,
    length => length === 0 && addDefault(),
  );

  watchRun($procedimentoPadrao, addDefault);

  return {
    addDefault,
    handleAdd,
    handleRemove,
    handleInput,
    emitInput,
  };
}

const procedimentoField = ({
  id,
  index,
  props,
  $showRemoveBtn,
  getSchema,
  handleAdd,
  handleRemove,
  handleInput,
  showAddBtn,
}: {
  id: string | undefined;
  index: number;
  props: IProps;
  $showRemoveBtn: Ref<boolean>;
  getSchema: (index: number) => IFormSchema<IAgendamentoProcedimentoModel>;
  handleAdd: () => void;
  handleRemove: (id: string | undefined) => void;
  handleInput: () => void;
  showAddBtn: (index: number) => boolean;
}) => (
  <div key={index} class="flex items-center">
    <FormFields
      form={props.form}
      schema={getSchema(index)}
      value={props.value[index]}
      onInput={handleInput}
      maxWidth={700}
    />

    {!props.hasRecebimentos && (
      <div class="mb-6" style={{ minWidth: '80px', width: '80px' }}>
        {$showRemoveBtn.value && (
          <BtnSmall
            remove
            tooltip="Remover procedimento"
            onClick={() => handleRemove(id)}
          />
        )}

        {showAddBtn(index) && (
          <BtnSmall add tooltip="Adicionar procedimento" onClick={handleAdd} />
        )}
      </div>
    )}
  </div>
);
