import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { ConstantsHelper } from '@/lib/constants/helper';
import { validationSummary } from '@/lib/form';
import { LookupsConfigs } from '@/lib/form/lookups';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { createComponent, watchRun } from '@/lib/vue';
import {
  IAgendamentoRecebimentoTransacaoModel,
  IFormSchema,
  IFormSubmitted,
  IFormValidationErrors,
  IRequireField,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  value?: IAgendamentoRecebimentoTransacaoModel;
  form: IFormSubmitted;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: IAgendamentoRecebimentoTransacaoModel) => void;
  onValidate: (errors: IFormValidationErrors[]) => void;
}

export const AgendamentoRecebimentoTransacaoFields = createComponent<
  IJsxProps,
  IEvents
>({
  name: 'AgendamentoRecebimentoTransacaoFields',
  props: {
    value: { type: Object, required: true },
    form: { type: Object, required: true },
  },
  setup(props: IProps, ctx) {
    const { $schema } = useComputeds(props);

    const { emitInput } = useEvents({ props, $schema, ctx });

    return () => (
      <PageSection title="Dados do recebimento" divider>
        <FormFields
          slot="fields"
          form={props.form}
          schema={$schema.value}
          value={props.value}
          onInput={emitInput}
        />
      </PageSection>
    );
  },
});

function useComputeds(props: IProps) {
  const $schema = computed<IFormSchema<IAgendamentoRecebimentoTransacaoModel>>(
    () => ({
      vencimento: {
        label: 'Vencimento',
        type: 'date',
        validations: { required: true },
        layout: { maxWidth: 200 },
      },
      formaPagamento: {
        label: 'Forma de pagamento',
        type: 'select',
        items: ConstantsHelper.formasPagamento,
        validations: { required: true },
        layout: { maxWidth: 250 },
      },
      contaFinanceiraId: {
        label: 'Conta financeira',
        type: 'autocomplete',
        itemLabel: 'nome',
        lookup: LookupsConfigs.contaFinanceira(),
        validations: {
          required: true,
        },
        layout: {
          input: {
            maxWidth: 420,
          },
        },
      },
      pago: {
        label: 'Pago',
        type: 'checkbox',
        layout: { maxWidth: 100 },
      },
      dataPagamento: {
        label: 'Data do pagamento',
        type: 'date',
        disabled: !props.value.pago,
        validations: {
          required: props.value.pago,
          maxDate: DateHelpers.today(),
        },
        layout: {
          maxWidth: 260,
        },
      },
    }),
  );

  return { $schema };
}

function useEvents({
  props,
  ctx,
  $schema,
}: {
  props: IProps;
  ctx: SetupContext;
  $schema: Ref<IFormSchema<IAgendamentoRecebimentoTransacaoModel>>;
}) {
  function handlePagoChange(pago: boolean) {
    if (!pago) {
      emitInput({
        ...props.value,
        dataPagamento: null,
      });
    }
  }

  function emitValidate() {
    const errors = validationSummary({
      schema: $schema.value,
      model: props.value,
      form: { submitted: true },
      customErrors: [],
    });

    ctx.emit('validate', errors);
  }

  function emitInput(value: IAgendamentoRecebimentoTransacaoModel) {
    ctx.emit('input', value);

    emitValidate();
  }

  watchRun(() => [props.form.submitted, props.value], emitValidate, {
    deep: true,
  });

  watchRun(() => props.value.pago, handlePagoChange);

  return {
    handlePagoChange,
    emitValidate,
    emitInput,
  };
}
