import {
  IFetchMoreSearchQuery,
  ListFetchMore,
} from '@/components/data/ListFetchMore';
import {
  PacienteDataNascimento,
  PacienteEmail,
  PacienteFones,
} from '@/components/paciente/PacienteComponents';
import { PageSection } from '@/components/page/PageSection';
import { Avatar } from '@/components/utils/Avatar';
import { QueryGraphql } from '@/graphql/query';
import { useState, useValue } from '@/lib/composables';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { pacienteWhere } from '@/lib/helpers/models/paciente';
import { AgendamentoService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { Routes } from '@/routes/routes';
import {
  AgendamentoOrderBy,
  IAgendamentoFragment,
  IAgendamentoFragment_paciente,
  IAgendamentosQuery,
  IAgendamentosQueryVariables,
  IPacienteFragment,
  IPacientesQuery,
  IPacientesQueryVariables,
  IRouteAgendamentoNewOptions,
  PacienteOrderBy,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IProps {
  search: string;
}

export const AgendaPacienteSearch = createComponent<IProps>({
  name: 'AgendaPacienteSearch',
  props: {
    search: { type: String, required: true },
  },
  setup(props, ctx) {
    const {
      $agendamentosLoading,
      setAgendamentosLoading,
      $pacientesLoading,
      setPacientesLoading,
    } = useData();

    const { $profissionalId, $agendamentosQuery, $pacientesQuery, $loading } =
      useComputeds({
        props,
        ctx,
        $agendamentosLoading,
        $pacientesLoading,
      });

    const { handleAgendamentoClick, routeNewAgendamento } =
      useEvents($profissionalId);

    return () => (
      <PageSection>
        <div class="flex font-normal text-gray-700 text-title">
          Resultado da busca por: "
          <span class="font-medium">{props.search}</span>"
        </div>

        <v-progress-linear
          indeterminate={$loading.value}
          height="1"
          class="mb-4"
        />

        {searchResults({
          title: 'Pacientes agendados',
          query: $agendamentosQuery.value,
          onLoading: setAgendamentosLoading,
          itemSlot: agendamentoCard(handleAgendamentoClick),
        })}

        {searchResults({
          title: 'Todos os pacientes',
          query: $pacientesQuery.value,
          onLoading: setPacientesLoading,
          itemSlot: pacienteCard(routeNewAgendamento),
        })}
      </PageSection>
    );
  },
});

function useData() {
  const [$agendamentosLoading, setAgendamentosLoading] = useValue(false);
  const [$pacientesLoading, setPacientesLoading] = useValue(false);

  return {
    $agendamentosLoading,
    setAgendamentosLoading,
    $pacientesLoading,
    setPacientesLoading,
  };
}

function useComputeds({
  props,
  ctx,
  $agendamentosLoading,
  $pacientesLoading,
}: {
  props: IProps;
  ctx: SetupContext;
  $agendamentosLoading: Ref<boolean>;
  $pacientesLoading: Ref<boolean>;
}) {
  const $profissionalId = useState(s => s.agenda.profissionalId!);

  const $agendamentosQuery = computed<IFetchMoreSearchQuery>(() => ({
    query: QueryGraphql.AgendamentosQuery,
    name: 'agendamentos',
    variables: (): IAgendamentosQueryVariables => ({
      where: {
        data_gte: DateHelpers.today().toSQLDate(),
        JOIN: {
          paciente: pacienteWhere(props.search),
        },
      },
      profissionalId: $profissionalId.value,
      orderBy: [
        AgendamentoOrderBy.data_ASC,
        AgendamentoOrderBy.horaInicial_ASC,
      ],
      take: 4,
    }),
    mapData: (result: IAgendamentosQuery) => result?.agendamentos.nodes,
    mapPageInfo: (result: IAgendamentosQuery) => result?.agendamentos.pageInfo,
  }));

  const $pacientesQuery = computed<IFetchMoreSearchQuery>(() => ({
    query: QueryGraphql.PacientesQuery,
    name: 'pacientes',
    variables: (): IPacientesQueryVariables => ({
      where: pacienteWhere(props.search),
      orderBy: [PacienteOrderBy.nome_ASC],
      take: 4,
    }),
    mapData: (result: IPacientesQuery) => result?.pacientes.nodes,
    mapPageInfo: (result: IPacientesQuery) => result?.pacientes.pageInfo,
  }));

  const $loading = computed(
    () => $agendamentosLoading.value && $pacientesLoading.value,
  );

  return {
    $profissionalId,
    $agendamentosQuery,
    $pacientesQuery,
    $loading,
  };
}

function useEvents($profissionalId: Ref<string>) {
  function handleAgendamentoClick(agendamento: IAgendamentoFragment) {
    return AgendamentoService.openViewDialog(agendamento);
  }

  function routeNewAgendamento(options?: IRouteAgendamentoNewOptions) {
    return Routes.app.agenda($profissionalId.value).agendamentos.new(options);
  }

  return {
    handleAgendamentoClick,
    routeNewAgendamento,
  };
}

const searchResults = ({
  title,
  query,
  onLoading,
  itemSlot,
}: {
  title: string;
  query: IFetchMoreSearchQuery;
  onLoading: (loading: boolean) => void;
  itemSlot: (slotArgs: any) => VueTsxSupport.JSX.Element;
}) => (
  <ListFetchMore
    config={query}
    onLoading={onLoading}
    scopedSlots={{ item: itemSlot }}
  >
    <div
      slot="title"
      class="mb-2 font-medium uppercase text-subtitle text-coolGray-700"
    >
      {title}
    </div>
  </ListFetchMore>
);

const PacienteInfo = createComponent<{
  paciente: IPacienteFragment | IAgendamentoFragment_paciente;
}>({
  name: 'AgendaPacienteSearchPacienteInfo',
  props: {
    paciente: { type: Object, required: true },
  },
  setup(props, ctx) {
    return () => {
      const {
        id,
        nome,
        imagem,
        celular,
        telefoneCasa,
        email,
        dataNascimento,
        sexo,
      } = props.paciente;

      return (
        <div class="flex space-x-4">
          <Avatar imgSrc={imagem?.thumbnailUrl} nome={nome} />

          <div class="flex flex-col space-y-1">
            <router-link
              to={Routes.app.pacientes.edit.index(id)}
              class="font-normal text-title"
            >
              {nome}
            </router-link>

            <div class="flex flex-wrap items-center space-x-4 text-gray-700 text-body-sm">
              <PacienteFones celular={celular} telefoneCasa={telefoneCasa} />

              <PacienteEmail email={email} />

              <PacienteDataNascimento dataNascimento={dataNascimento} />

              {sexo && <div>Sexo: {sexo}</div>}
            </div>
          </div>
        </div>
      );
    };
  },
});

const agendamentoCard =
  (handleAgendamentoClick: (agendamento: IAgendamentoFragment) => any) =>
  ({ item: agendamento }: { item: IAgendamentoFragment }) =>
    (
      <v-card outlined class="p-2 mb-3 bg-gray-50">
        <div class="flex items-center">
          <div class="flex flex-col">
            <div class="mb-2 text-title text-coolGray-700">
              {DateHelpers.dataAgendamento(agendamento)}
            </div>

            <PacienteInfo paciente={agendamento.paciente!} />
          </div>

          <v-spacer />

          <v-btn
            outlined
            color="primary"
            class="mx-4"
            onClick={() => handleAgendamentoClick(agendamento)}
          >
            Detalhes
          </v-btn>
        </div>
      </v-card>
    );

const pacienteCard =
  (routeNewAgendamento: (options?: IRouteAgendamentoNewOptions) => any) =>
  ({ item: paciente }: { item: IPacienteFragment }) =>
    (
      <v-card outlined class="p-2 mb-3">
        <div class="flex items-center">
          <PacienteInfo paciente={paciente} />

          <v-spacer />

          <v-btn
            outlined
            to={routeNewAgendamento({
              pacienteId: paciente.id,
            })}
            color="success"
            class="mx-4"
          >
            Agendar
          </v-btn>
        </div>
      </v-card>
    );
