import { MyPage } from '@/components/page/MyPage';
import { useValue } from '@/lib/composables';
import { useFormPage } from '@/lib/composables/form/useFormPage';
import { useAgendaPage } from '@/lib/composables/page/useAgendaPage';
import { useBreakpoints } from '@/lib/composables/utils/useBreakpoints';
import { ListaEsperaService } from '@/lib/services';
import { createComponent } from '@/lib/vue';
import { IListaEsperaFragment, IListaEsperaModel } from '@/typings';
import { ListaEsperaForm } from '../components/forms/ListaEsperaForm';
import { ListaEsperaPacientes } from '../components/listaEspera/ListaEsperaPacientes';

export default createComponent({
  name: 'ListaEsperaPage',
  setup(props, ctx) {
    const [$ordem, setOrdem] = useValue(0);

    const { profissionalId } = useAgendaPage();

    const { $xs } = useBreakpoints();

    const { page, handleSubmit } = useFormPage<IListaEsperaModel>({
      ctx,
      async submitCallback(model) {
        return ListaEsperaService.create({
          profissionalId,
          model,
          ordem: $ordem.value,
        });
      },
    });

    function handleReorder(items: IListaEsperaFragment[]) {
      return ListaEsperaService.updateOrdem({
        profissionalId,
        items,
      });
    }

    return () => (
      <MyPage title="Lista de espera">
        <div class="flex">
          <ListaEsperaPacientes
            profissionalId={profissionalId}
            onReorder={handleReorder}
            onOrdemChange={setOrdem}
          />

          <div class="flex w-full sm:w-1/2">
            <div class="flex flex-col flex-no-wrap md:flex-row">
              <v-divider vertical={!$xs.value} class="md:my-2" />

              <ListaEsperaForm page={page} onSubmit={handleSubmit} />
            </div>
          </div>
        </div>
      </MyPage>
    );
  },
});
