import { MyForm } from '@/components/form/MyForm';
import { PageSection } from '@/components/page/PageSection';
import { useValue } from '@/lib/composables';
import {
  IFormEvents,
  IFormProps,
  useFormConfig,
} from '@/lib/composables/form/useFormConfig';
import {
  AgendamentoService,
  ConfiguracaoFinanceiraService,
} from '@/lib/services';
import { createComponent } from '@/lib/vue';
import {
  AgendamentoViewProcedimentoTableMode,
  IAgendamentoFragment,
  IAgendamentoRecebimentoFormModel,
  IAgendamentoRecebimentoProcedimentoModel,
  IFormValidationErrors,
} from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';
import { AgendamentoProcedimentosTable } from '../dialogs/table/AgendamentoProcedimentosTable';
import { AgendamentoRecebimentoParcelasTable } from '../dialogs/table/AgendamentoRecebimentoParcelasTable';
import { AgendamentoRecebimentoFormActionsRow } from './agendamentoRecebimentoForm/AgendamentoRecebimentoFormActionsRow';
import { AgendamentoRecebimentoHeader } from './agendamentoRecebimentoForm/AgendamentoRecebimentoHeader';
import { AgendamentoRecebimentoParcelasFields } from './agendamentoRecebimentoForm/AgendamentoRecebimentoParcelasFields';
import { AgendamentoRecebimentoTransacaoFields } from './agendamentoRecebimentoForm/AgendamentoRecebimentoTransacaoFields';

interface IProps extends IFormProps {}

interface IEvents extends IFormEvents<IAgendamentoRecebimentoFormModel> {
  onRecebimentoStatusChanged: (values: {
    transacaoId: string;
    pago: boolean;
  }) => void;
}

export const AgendamentoRecebimentoForm = createComponent<IProps, IEvents>({
  name: 'AgendamentoRecebimentoForm',
  props: {
    page: { type: Object, required: true },
  },
  setup(props, ctx) {
    const {
      $agendamento,
      setAgendamento,
      $transacoes,
      $hasTransacoes,
      $hasParcelas,
    } = useData();

    const {
      $form,
      emitCancel,
      emitSubmit,
      setTransacaoErrors,
      setParcelasErrors,
    } = useForm({
      props,
      ctx,
      setAgendamento,
      $agendamento,
      $transacoes,
      $hasTransacoes,
    });

    const {
      setProcedimentos,
      handleDescontoChange,
      handleValorTotalChange,
      emitRecebimentoStatusChanged,
      handleSubmit,
    } = useActions({ ctx, $form, emitSubmit });

    return () => (
      <MyForm form={$form.value} noSummary noActions>
        {$agendamento.value && (
          <PageSection>
            <AgendamentoRecebimentoHeader agendamento={$agendamento.value} />

            <AgendamentoProcedimentosTable
              agendamento={$agendamento.value}
              mode={
                $hasParcelas.value
                  ? AgendamentoViewProcedimentoTableMode.VALORES
                  : AgendamentoViewProcedimentoTableMode.EDIT
              }
              onProcedimentosChange={setProcedimentos}
              onDescontoChange={handleDescontoChange}
              onValorTotalChange={handleValorTotalChange}
            />
          </PageSection>
        )}

        {!$hasParcelas.value && (
          <AgendamentoRecebimentoTransacaoFields
            form={$form.value}
            v-model={$form.value.model.transacao}
            onValidate={setTransacaoErrors}
          />
        )}

        {!$hasTransacoes.value && (
          <AgendamentoRecebimentoParcelasFields
            form={$form.value}
            valor={$form.value.model.agendamento.valorTotal}
            vencimento={$form.value.model.transacao.vencimento}
            v-model={$form.value.model.parcelas}
            onValidate={setParcelasErrors}
          />
        )}

        {$hasParcelas.value && (
          <AgendamentoRecebimentoParcelasTable
            agendamentoId={$agendamento.value!.id}
            onRecebimentoStatusChanged={emitRecebimentoStatusChanged}
          />
        )}

        <AgendamentoRecebimentoFormActionsRow
          hasParcelas={$hasParcelas.value}
          onCancel={emitCancel}
          onSubmit={handleSubmit}
        />
      </MyForm>
    );
  },
});

function useData() {
  const [$agendamento, setAgendamento] = useValue<
    IAgendamentoFragment | null | undefined
  >(null);
  const $transacoes = computed(() => $agendamento.value?.transacoes);

  const $hasTransacoes = computed(() => !!$transacoes.value?.length);

  const $hasParcelas = computed(
    () => !!$transacoes.value && $transacoes.value.length > 1,
  );

  return {
    $agendamento,
    setAgendamento,
    $transacoes,
    $hasTransacoes,
    $hasParcelas,
  };
}

function useForm({
  props,
  ctx,
  setAgendamento,
  $agendamento,
  $transacoes,
  $hasTransacoes,
}: {
  props: IProps;
  ctx: SetupContext;
} & Pick<
  ReturnType<typeof useData>,
  'setAgendamento' | '$agendamento' | '$transacoes' | '$hasTransacoes'
>) {
  const [$transacaoErrors, setTransacaoErrors] = useValue<
    IFormValidationErrors[]
  >([]);
  const [$parcelasErrors, setParcelasErrors] = useValue<
    IFormValidationErrors[]
  >([]);

  const { $form, emitCancel, emitSubmit } = useFormConfig<
    IAgendamentoRecebimentoFormModel,
    any
  >({
    page: props.page,
    initialValue: {
      agendamento: {
        desconto: null,
        valorTotal: null,
      },
      transacao: {
        vencimento: null,
        pago: false,
        dataPagamento: null,
        formaPagamento: null,
        contaFinanceiraId: null,
      },
      procedimentos: [],
      parcelas: {
        parcelar: false,
        valorEntrada: null,
        numero: null,
      },
    },
    mapSchema: () => ({}),
    ctx,
    scrollOnValidation: false,
    mapCustomErrors: () => [
      ...$transacaoErrors.value,
      ...$parcelasErrors.value,
    ],
    async loadEditCallback({ id, setFormModel }) {
      setAgendamento(await AgendamentoService.getById(id));
      if (!$agendamento.value) {
        return emitCancel();
      }

      if (!$hasTransacoes.value) {
        const config = await ConfiguracaoFinanceiraService.get();
        if (!config) {
          return emitCancel();
        }

        setFormModel({
          agendamento: {
            desconto: $agendamento.value.desconto,
            valorTotal: $agendamento.value.valorTotal,
          },
          transacao: {
            vencimento: null,
            formaPagamento: config.formaPagamento,
            contaFinanceiraId: config.contaFinanceira.id,
            pago: false,
            dataPagamento: null,
          },
          procedimentos: [],
          parcelas: {
            parcelar: false,
            valorEntrada: null,
            numero: null,
          },
        });
      } else if ($transacoes.value!.length === 1) {
        const transacao = $transacoes.value![0];

        setFormModel({
          agendamento: {
            desconto: $agendamento.value.desconto,
            valorTotal: $agendamento.value.valorTotal,
          },
          transacao: {
            vencimento: transacao.vencimento,
            pago: transacao.pago,
            dataPagamento: transacao.dataPagamento,
            formaPagamento: transacao.formaPagamento,
            contaFinanceiraId: transacao.contaFinanceira.id,
          },
          procedimentos: [],
          parcelas: {
            parcelar: false,
            valorEntrada: null,
            numero: null,
          },
        });
      }
    },
  });

  return {
    $form,
    emitCancel,
    emitSubmit,
    setTransacaoErrors,
    setParcelasErrors,
  };
}

function useActions({
  ctx,
  $form,
  emitSubmit,
}: { ctx: SetupContext } & Pick<
  ReturnType<typeof useForm>,
  '$form' | 'emitSubmit'
>) {
  // is better to control procedimentos without $form
  const [$procedimentos, setProcedimentos] = useValue<
    IAgendamentoRecebimentoProcedimentoModel[]
  >([]);

  function handleDescontoChange(desconto: number) {
    $form.value.model.agendamento.desconto = desconto;
  }

  function handleValorTotalChange(valorTotal: number) {
    $form.value.model.agendamento.valorTotal = valorTotal;
  }

  function emitRecebimentoStatusChanged({
    transacaoId,
    pago,
  }: {
    transacaoId: string;
    pago: boolean;
  }) {
    ctx.emit('recebimentoStatusChanged', { transacaoId, pago });
  }

  function handleSubmit() {
    // update form.model.procedimentos
    $form.value.model.procedimentos = $procedimentos.value;

    emitSubmit();
  }

  return {
    setProcedimentos,
    handleDescontoChange,
    handleValorTotalChange,
    emitRecebimentoStatusChanged,
    handleSubmit,
  };
}
