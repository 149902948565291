import { FormFields } from '@/components/form/fields/FormFields';
import { PageSection } from '@/components/page/PageSection';
import { validationSummary } from '@/lib/form';
import { createComponent, watchRun } from '@/lib/vue';
import { TransacaoRepeticaoPreview } from '@/modules/financas/transacoes/components/forms/TransacaoRepeticaoPreview';
import {
  IAgendamentoRecebimentoParcelasModel,
  IFormSchema,
  IFormSubmitted,
  IFormValidationErrors,
  IRequireField,
  ITransacaoRepeticaoModel,
  TransacaoRepeticaoModo,
} from '@/typings';
import { computed, Ref, SetupContext } from '@vue/composition-api';

interface IJsxProps {
  value?: IAgendamentoRecebimentoParcelasModel;
  form: IFormSubmitted;
  valor: number | null | undefined;
  vencimento: string | null;
}

type IProps = IRequireField<IJsxProps, 'value'>;

interface IEvents {
  onInput: (value: IAgendamentoRecebimentoParcelasModel) => void;
  onValidate: (errors: IFormValidationErrors[]) => void;
}

export const AgendamentoRecebimentoParcelasFields = createComponent<
  IJsxProps,
  IEvents
>({
  name: 'AgendamentoRecebimentoParcelasFields',
  props: {
    form: { type: Object, required: true },
    value: { type: Object, required: true },
    valor: { type: Number },
    vencimento: { type: String },
  },
  setup(props: IProps, ctx) {
    const { $previewConfig, $schema } = useComputeds(props);

    const { emitInput } = useEvents({ props, ctx, $schema });

    return () => (
      <PageSection title="Parcelamento" divider>
        <div class="flex flex-col" slot="fields">
          <FormFields
            form={props.form}
            schema={$schema.value}
            value={props.value}
            onInput={emitInput}
          />

          <TransacaoRepeticaoPreview
            repeticao={$previewConfig.value}
            valor={props.valor}
            vencimento={props.vencimento}
          />
        </div>
      </PageSection>
    );
  },
});

function useComputeds(props: IProps) {
  const $schema = computed<IFormSchema<IAgendamentoRecebimentoParcelasModel>>(
    () => ({
      parcelar: {
        label: 'Parcelar',
        type: 'switch',
        layout: { maxWidth: 130 },
      },
      valorEntrada: {
        label: 'Entrada de',
        type: 'money',
        hidden: !props.value.parcelar,
        layout: { maxWidth: 166 },
      },
      numero: {
        label: 'Número de parcelas',
        type: 'number',
        integer: true,
        hidden: !props.value.parcelar,
        validations: {
          required: props.value.parcelar,
          gt: 0,
          lte: 24,
        },
        layout: { maxWidth: 210 },
      },
    }),
  );

  const $valorParcela = computed(() => {
    if (!props.valor || !props.value.numero) {
      return null;
    }

    const valorEntrada = props.value.valorEntrada || 0;

    return (props.valor - valorEntrada) / props.value.numero;
  });

  const $previewConfig = computed<ITransacaoRepeticaoModel>(() => ({
    geral: {
      repetir: props.value.parcelar,
      modo: TransacaoRepeticaoModo.PARCELAS,
    },
    repeticoes: {
      numeroRepeticoes: null,
      tipoRepeticao: null,
    },
    parcelas: {
      numeroParcelas: props.value.numero,
      valorEntrada: props.value.valorEntrada,
      valorParcela: $valorParcela.value,
    },
  }));

  return {
    $schema,
    $valorParcela,
    $previewConfig,
  };
}

function useEvents({
  props,
  ctx,
  $schema,
}: {
  props: IProps;
  ctx: SetupContext;
  $schema: Ref<IFormSchema<IAgendamentoRecebimentoParcelasModel>>;
}) {
  function emitValidate() {
    const errors = validationSummary({
      schema: $schema.value,
      model: props.value,
      form: { submitted: true },
      customErrors: [],
    });

    ctx.emit('validate', errors);
  }

  function emitInput(value: IAgendamentoRecebimentoParcelasModel) {
    ctx.emit('input', value);

    emitValidate();
  }

  watchRun(() => [props.form.submitted, props.value], emitValidate, {
    deep: true,
  });

  return {
    emitValidate,
    emitInput,
  };
}
